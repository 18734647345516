import React from 'react';
import {useHistory, useParams } from 'react-router-dom';
import ReservationHistoryListComponent from '../reservation/ReservationHistoryListComponent';
import ReservationListComponent from "../reservation/ReservationListComponent";

interface routerParams {
    day:string,
    dayId: string,
    people: string,
    isHistory: string
}

interface IDayReservationsAdminScreen {
    showNotification?: any,
    messageNotification?: any,
    showSidebar: boolean,
    setShowSidebar: any
}

const DayReservationsAdminScreen = (props: IDayReservationsAdminScreen) => {

    const history = useHistory();
    const params = useParams<routerParams>();

    function onChangeShow() {
        if (props.showSidebar)
            props.setShowSidebar(false);
        else
            props.setShowSidebar(true)
    }

    return (
        <div className={"w-full h-full md:py-4 md:px-20"}>
            <div className={"flex flex-row justify-between w-full"}>
                <button className={"bg-white border-1 border-primary rounded-lg lg:hidden py-2 px-4"} onClick={onChangeShow}>
                    <svg xmlns="http://www.w3.org/2000/svg" className={"w-4 h-4"} viewBox="0 0 38 26.333">
                        <g id="Icon_feather-menu" data-name="Icon feather-menu" transform="translate(-3 -7.5)">
                            <path id="Trazado_115" data-name="Trazado 115" d="M4.5,18h35" transform="translate(0 2.667)" fill="none" stroke="#6B0DBC" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                            <path id="Trazado_116" data-name="Trazado 116" d="M4.5,9h35" transform="translate(0)" fill="none" stroke="#6B0DBC" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                            <path id="Trazado_117" data-name="Trazado 117" d="M4.5,27h35" transform="translate(0 5.333)" fill="none" stroke="#6B0DBC" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                        </g>
                    </svg>
                </button>
            </div>
            <div className={"flex flex-col w-full h-full animate-fade-in p-8 shadow-custom rounded-lg my-2"}>
                <div className={"flex flex-row w-full"}>
                    <button className={"rounded-full hover:bg-gray-50 mr-2"} onClick={() => history.goBack()}>
                        <div className={"w-8 h-8 p-2"}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                <path id="Icon_material-arrow_back" data-name="Icon material-arrow_back" d="M30,16.5H11.745L20.13,8.115,18,6,6,18,18,30l2.115-2.115L11.745,19.5H30Z" transform="translate(-6 -6)"/>
                            </svg>
                        </div>
                    </button>

                    <p className={"text-xl text-primary font-bold"}>{params.day}</p>
                </div>

                <br/>
                <p className={"text-lg"}>Reservas</p>

                {
                    (params.isHistory === "true")
                        ? <ReservationHistoryListComponent dayId={params.dayId} maxPeople={params.people} showNotification={props.showNotification} messageNotification={props.messageNotification} />
                        : <ReservationListComponent dayId={params.dayId} maxPeople={params.people} showNotification={props.showNotification} messageNotification={props.messageNotification}/>
                }
            </div>
        </div>
    );
};

export default DayReservationsAdminScreen;
