import * as Yup from 'yup'

export const dayValidation = Yup.object({
    date: Yup.string().required('Este campo es obligatorio'),
    turn: Yup.string().required('Este campo es obligatorio'),
    maxPeople: Yup.number().min(1, 'Debe ser mayor que 0'),
    openHour: Yup.string().required('Este campos es obligatorio'),
    closeHour: Yup.string().required('Este campo es obligatorio')
});

