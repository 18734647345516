import React, {useContext, useEffect, useReducer, useState} from "react";
import day from '../../api/day'
import {IDay} from "../../models/day";
import {dayReducer} from '../../reducers/dayReducer';
import LoadingComponent from "../ui/LoadingComponent";
import { useHistory } from "react-router-dom";
import {AuthContext} from "../../context/AuthContext";
import PaginationComponent from "../ui/PaginationComponent";
import {AxiosError} from "axios";

interface IDayListScreen {
    showNotification: any,
    messageNotification: any,
    showSidebar: boolean,
    setShowSidebar: any
}

const DayListScreen = (props: IDayListScreen) => {
    const buttonStyles = "h-6 border-1 border-secondary rounded-full mt-2 w-full text-primary text-sm transition duration-300 ease-in-out hover:bg-primary-hover hover:text-white";
    const textStyles = "text-indigo-500 font-bold text-sm";

    const initialState: IDay[] = [];

    const {user, userDispatch} = useContext(AuthContext);
    const [state, dispatch] = useReducer(dayReducer, initialState);
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const [totalPages, setTotalPages] = useState(0);
    const [countRecordsPerPages, setCountRecordsPerPages] = useState(8);
    const [currentPage, setCurrentPage] = useState(1);

    function onChangeShow() {
        if (props.showSidebar)
            props.setShowSidebar(false);
        else
            props.setShowSidebar(true)
    }

    function checkError(error: string) {
        if (error.includes('500')) {
            props.messageNotification('Error interno del servidor, comprueba internet y vuelve a intentarlo luego');
            props.showNotification(true);
        } else if (error.includes('404')) {
            props.messageNotification('El dato que quiere borrar no existe');
            props.showNotification(true);
        } else if (error.includes('400')) {
            props.messageNotification('La operación ha salido mal');
            props.showNotification(true);
        } else if (error.includes('Network Error')) {
            userDispatch({
                user: null,
            });
            history.replace('/login')
        } else {
            props.messageNotification('Error desconocido');
            props.showNotification(true);
        }
    }

    useEffect(() => {
        setLoading(true);

        day.listHistory(user?.user?.token as string, currentPage, countRecordsPerPages)
            .then(response => {
                const totalRecords = response.totalCountRecords;
                setTotalPages(Math.ceil(totalRecords / countRecordsPerPages));

                const action = {
                    type: 'charge',
                    payload: response.days
                };

                dispatch(action);
            })
            .catch((error) => checkError(error.toString())).finally(() => setLoading(false));
    }, [currentPage, countRecordsPerPages]);

    return (
        <>
           <div className={(loading) ? 'block w-full h-full flex flex-col justify-center items-center animate-fade-in' : 'hidden'}>
               <div className={"w-40 h-40"}>
                   <LoadingComponent />
               </div>
           </div>

            <div className={ (loading) ? "hidden" : "block h-full w-full md:py-4 md:px-20 lg:w-auto"}>
                <div className={"flex flex-row justify-between w-full"}>
                    <button className={"bg-white border-1 border-primary rounded-lg lg:hidden py-2 px-4"} onClick={onChangeShow}>
                        <svg xmlns="http://www.w3.org/2000/svg" className={"w-4 h-4"} viewBox="0 0 38 26.333">
                            <g id="Icon_feather-menu" data-name="Icon feather-menu" transform="translate(-3 -7.5)">
                                <path id="Trazado_115" data-name="Trazado 115" d="M4.5,18h35" transform="translate(0 2.667)" fill="none" stroke="#6B0DBC" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                                <path id="Trazado_116" data-name="Trazado 116" d="M4.5,9h35" transform="translate(0)" fill="none" stroke="#6B0DBC" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                                <path id="Trazado_117" data-name="Trazado 117" d="M4.5,27h35" transform="translate(0 5.333)" fill="none" stroke="#6B0DBC" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                            </g>
                        </svg>
                    </button>
                    <div className={"hidden md:flex flex-col justify-center items-center"}>
                        <PaginationComponent currentPage={currentPage} totalCountRecords={totalPages} onChange={newPage => setCurrentPage(newPage)}/>
                    </div>
                </div>
                <div className={"block md:hidden w-full h-16 flex flex-row justify-center"}>
                    <PaginationComponent currentPage={currentPage} totalCountRecords={totalPages} onChange={newPage => setCurrentPage(newPage)}/>
                </div>
                <div className="w-full h-full overflow-y-scroll px-2">
                    <div className={"flex flex-row w-full py-8 mb-16 gap-4 flex-wrap animate-fade-in justify-center md:justify-start place-self-start"}>
                        {state.map((value: any) => (
                            value.map((trueValue: IDay) => (
                                <div key={trueValue.id} className={"flex flex-col w-full md:w-52 h-auto p-4 shadow-custom rounded-lg"}>
                                    <div className={"flex flex-row items-center"}>
                                        <p className={"font-bold text-sm lg:text-base"}>{trueValue.date}</p>
                                    </div>
                                    <p><span className={textStyles}>Turno:</span> {trueValue.turn}</p>
                                    <p><span className={textStyles}>Aforo máximo:</span> {trueValue.maxPeople}</p>
                                    <p><span className={textStyles}>Aforo Ocupado:</span> {trueValue.people}</p>
                                    <p><span className={textStyles}>Número de reservas: </span> {trueValue.reservationsCount} </p>
                                    <hr/>
                                    <p><span className={textStyles}>Hora de apertura: </span> {trueValue.openHour} </p>
                                    <p><span className={textStyles}>Hora de cierre: </span> {trueValue.closeHour} </p>
                                    <hr/>
                                    <button className={buttonStyles} onClick={() => history.push(`/day/reservations/${trueValue.id}&${trueValue.date}&${trueValue.maxPeople}&true`)}>Ver reservas</button>
                                </div>
                            ))
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
};

export default DayListScreen
