import {Redirect, Route, RouteProps } from "react-router-dom";
import React, {useContext} from "react";
import {AuthContext} from "../context/AuthContext";

interface IPublicRoute extends RouteProps {}

export const PublicRoute: React.FC<IPublicRoute> = ({...rest}) => {
    const context = useContext(AuthContext);

    if (context.user?.user?.token) return <Redirect to={"/"} />;
    return (
        <Route {...rest}/>
    )
};
