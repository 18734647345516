import LoadingComponent from "../ui/LoadingComponent";
import React, {useContext, useEffect, useState} from "react";
import {ErrorMessage, Field, Form, Formik} from "formik";
import plate from "../../api/plate";
import {IWeekMenuForCreation, IWeekMenuWithPlates} from "../../models/weekmenu";
import {useHistory, useParams} from "react-router-dom";
import MultipleSelector from "../ui/MultipleSelector";
import {IPlatesNames} from "../../models/plate";
import weekMenu from "../../api/weekMenu";
import {weekMenuValidation} from "../../validation/weekMenuValidation";
import {AuthContext} from "../../context/AuthContext";

interface IWeekMenuFormScreen {
    showNotification?: any,
    messageNotification?: any,
    requestType: string,
    showSidebar: boolean,
    setShowSidebar: any
}

interface IParams {
    id: string
}

const WeekMenuFormScreen = (props: IWeekMenuFormScreen) => {

    const labelStyles = "font-bold mb-2";
    const inputStyles = "border-1 border-primary rounded-lg p-1 mb-2";

    const history = useHistory();
    const params = useParams<IParams>();
    const { user, userDispatch } = useContext(AuthContext);

    const platesInitState: IPlatesNames[] = [
        {
            id:0,
            name: '',
            type: ''
        }
    ];

    const initialState: IWeekMenuWithPlates = {
        id: 0,
        name: "",
        initDate: "",
        finishDate: "",
        isTasting: false,
        price: "",
        plateses: [{
            plate: {
                id: 0,
                name: "",
                type: ''
            }
        }]
    };

    const initialSelectedState: any[] = [];

    const [selected, setSelected] = useState(initialSelectedState);
    const [plates, setPlates] = useState(platesInitState);
    const [loading, setLoading] = useState(false);
    const [weekMenuState, setWeekMenuState] = useState(initialState);

    function onChangeShow() {
        if (props.showSidebar)
            props.setShowSidebar(false);
        else
            props.setShowSidebar(true)
    }

    function checkError(error: string) {
        if (error.includes('500')) {
            props.messageNotification('Error interno del servidor, comprueba internet y vuelve a intentarlo luego');
            props.showNotification(true);
        } else if (error.includes('404')) {
            props.messageNotification('El dato que quiere borrar no existe');
            props.showNotification(true);
        } else if (error.includes('400')) {
            props.messageNotification('La operación ha salido mal');
            props.showNotification(true);
        } else if (error.includes('Network Error')) {
            userDispatch({
                user: null,
            });
            history.replace('/login')
        } else {
            props.messageNotification('Error desconocido');
            props.showNotification(true);
        }
    }

    useEffect( () => {
        setLoading(true);
        plate.getNames(user?.user?.token as string)
            .then((response) => setPlates(response))
            .catch((error) => checkError(error.toString()))
            .finally(() => setLoading(false));
        if (props.requestType === "update") {
            setLoading(true);
            weekMenu.get(parseInt(params.id), user?.user?.token as string)
                .then(async (response) => {
                    setWeekMenuState(response);
                })
                .catch((error) => checkError(error.toString()))
                .finally(() => setLoading(false))
        }
    }, []);

    return (
        <>
            <div className={(loading) ? 'block w-full h-full flex flex-col justify-center items-center animate-fade-in ' : 'hidden'}>
                <div className={"w-40 h-40"}>
                    <LoadingComponent />
                </div>
            </div>
            <div className={"flex flex-col h-full " + ((loading) ? "hidden" : "block")}>
                <div className={"flex flex-row justify-between w-full"}>
                    <button className={"bg-white border-1 border-primary rounded-lg lg:hidden py-2 px-4"} onClick={onChangeShow}>
                        <svg xmlns="http://www.w3.org/2000/svg" className={"w-4 h-4"} viewBox="0 0 38 26.333">
                            <g id="Icon_feather-menu" data-name="Icon feather-menu" transform="translate(-3 -7.5)">
                                <path id="Trazado_115" data-name="Trazado 115" d="M4.5,18h35" transform="translate(0 2.667)" fill="none" stroke="#6B0DBC" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                                <path id="Trazado_116" data-name="Trazado 116" d="M4.5,9h35" transform="translate(0)" fill="none" stroke="#6B0DBC" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                                <path id="Trazado_117" data-name="Trazado 117" d="M4.5,27h35" transform="translate(0 5.333)" fill="none" stroke="#6B0DBC" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                            </g>
                        </svg>
                    </button>
                </div>
                <div className="w-full h-full overflow-y-scroll">
                    <div className={"mt-4 flex flex-row animate-fade-in flex-wrap p-2"}>
                        <div className={"flex flex-col p-8 w-full md:w-1/2 h-auto shadow-custom rounded-lg"}>
                            <div className={"flex flex-row w-full items-center "}>
                                <button className={"rounded-full hover:bg-gray-50 mr-2"} onClick={() => history.goBack()}>
                                    <div className={"w-8 h-8 p-2"}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                            <path id="Icon_material-arrow_back" data-name="Icon material-arrow_back" d="M30,16.5H11.745L20.13,8.115,18,6,6,18,18,30l2.115-2.115L11.745,19.5H30Z" transform="translate(-6 -6)"/>
                                        </svg>
                                    </div>
                                </button>
                                {(props.requestType !== "update") ?
                                    <p className={"font-bold text-primary text-lg"}>Crear menú</p> :
                                    <p className={"font-bold text-primary text-lg"}>Actualizar menú</p>}
                            </div>

                            <Formik
                                enableReinitialize={true}
                                initialValues={weekMenuState}
                                onSubmit={async values => {
                                    setLoading(true);

                                    let formData = new FormData();
                                    formData.append("name", values.name);
                                    formData.append("initDate", values.initDate);
                                    formData.append("finishDate", values.finishDate);
                                    formData.append("price", (values.isTasting) ? values.price : "");
                                    formData.append("isTasting", (values.isTasting) ? "true" : "false");
                                    formData.append("platesIds", '[' + selected.toString() + ']');

                                    if (props.requestType === "create") {
                                        await weekMenu.create(formData, user?.user?.token as string, user?.user?.userResponse?.name as string + ' '
                                        + user?.user?.userResponse?.lastName as string)
                                            .then(() => {
                                                setLoading(false);
                                                history.goBack();
                                                props.messageNotification('Se ha insertado correctamente');
                                                props.showNotification(true);
                                            })
                                            .catch((error) => checkError(error.toString()))
                                            .finally(() => setLoading(false));
                                    } else if (props.requestType === "update"){
                                        await weekMenu.put(parseInt(params.id), formData, user?.user?.token as string, user?.user?.userResponse?.name as string + ' '
                                        + user?.user?.userResponse?.lastName as string)
                                            .then(() => {
                                                setLoading(false);
                                                history.goBack();
                                                props.messageNotification('Se ha actualizado correctamente');
                                                props.showNotification(true);
                                            })
                                            .catch((error) => checkError(error.toString()))
                                            .finally(() => setLoading(false))
                                    }
                                }}
                                validationSchema={weekMenuValidation}
                            >{(formiprops) => (
                                <Form className={"mt-4"}>
                                    <div className={"flex flex-col"}>
                                        <label htmlFor={"name"} className={labelStyles}>Nombre del menu*</label>
                                        <Field type={"text"} name={"name"} className={inputStyles} />
                                        <ErrorMessage name={"name"}>{message =>
                                            <p className={"text-red-500"}>{message}</p>
                                        }</ErrorMessage>
                                    </div>
                                    <div className={"flex flex-col"}>
                                        <label htmlFor={"initDate"} className={labelStyles}>Fecha de inicio*</label>
                                        <Field type={"date"} name={"initDate"} className={inputStyles} />
                                        <ErrorMessage name={"initDate"}>{message =>
                                            <p className={"text-red-500"}>{message}</p>
                                        }</ErrorMessage>
                                    </div>
                                    <div className={"flex flex-col"}>
                                        <label htmlFor={"finishDate"} className={labelStyles}>Fecha de fin*</label>
                                        <Field type={"date"} name={"finishDate"} className={inputStyles} />
                                        <ErrorMessage name={"finishDate"}>{message =>
                                            <p className={"text-red-500"}>{message}</p>
                                        }</ErrorMessage>
                                    </div>
                                    <div className={(formiprops.values.isTasting) ? "flex flex-col" : "hidden"}>
                                        <label htmlFor={"price"} className={labelStyles}>Precio</label>
                                        <Field type={"text"} name={"price"} className={inputStyles} />
                                    </div>
                                    <div className="flex flex-col">
                                        <label htmlFor={"isTasting"} className={labelStyles}>
                                            <Field type={"checkbox"} name={"isTasting"} className={inputStyles} />
                                            &nbsp;&nbsp;Menú degustación
                                        </label>

                                    </div>

                                    <input type="submit" value={"Guardar"} className={"bg-primary text-white p-2 rounded-lg mt-2 cursor-pointer"}/>
                                </Form>
                            )}
                            </Formik>
                        </div>

                        <MultipleSelector items={plates} selected={selected} setSelected={setSelected} plateses={weekMenuState.plateses}/>
                    </div>
                </div>
            </div>
        </>
    )
};

export default WeekMenuFormScreen;
