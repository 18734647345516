import React, {useContext, useEffect, useState} from 'react';
import {IDayForCreation} from "../../models/day";
import {Formik, Form, Field, ErrorMessage} from 'formik';
import day from '../../api/day'
import { useHistory, useParams } from 'react-router-dom';
import LoadingComponent from "../ui/LoadingComponent";
import {dayValidation} from "../../validation/dayValidation";
import {AuthContext} from "../../context/AuthContext";

interface IDayFormScreen {
    showNotification?: any,
    messageNotification?: any,
    requestType: string,
    showSidebar: boolean,
    setShowSidebar: any
}

interface IParams {
    id: string
}

const DayFormScreen = (props: IDayFormScreen) => {

    const labelStyles = "font-bold mb-2";
    const inputStyles = "border-1 border-primary rounded-lg p-1 mb-2";

    const initialState: IDayForCreation = {
        date: "",
        maxPeople: 0,
        turn: "",
        openHour: "",
        closeHour: "",
    };

    const history = useHistory();
    const params = useParams<IParams>();
    const { user, userDispatch } = useContext(AuthContext);

    const [loading, setLoading] = useState(false);
    const [dayState, setDayState] = useState<IDayForCreation>(initialState);

    function onChangeShow() {
        if (props.showSidebar)
            props.setShowSidebar(false);
        else
            props.setShowSidebar(true)
    }

    function checkError(error: string) {
        if (error.includes('500')) {
            props.messageNotification('Error interno del servidor, comprueba internet y vuelve a intentarlo luego');
            props.showNotification(true);
        } else if (error.includes('404')) {
            props.messageNotification('El dato que quiere borrar no existe');
            props.showNotification(true);
        } else if (error.includes('400')) {
            props.messageNotification('La operación ha salido mal');
            props.showNotification(true);
        } else if (error.includes('Network Error')) {
            userDispatch({
                user: null,
            });
            history.replace('/login')
        } else {
            props.messageNotification('Error desconocido');
            props.showNotification(true);
        }
    }

    useEffect(() => {
        if (props.requestType === "update") {
            setLoading(true);
            day.get(parseInt(params.id), user?.user?.token as string)
                .then((response) => setDayState(response))
                .catch((error) => checkError(error.toString()))
                .finally(() => setLoading(false));
        }
    }, []);

    return (
        <>
            <div className={(loading) ? 'block w-full h-full flex flex-col justify-center items-center animate-fade-in ' : 'hidden'}>
                <div className={"w-40 h-40"}>
                    <LoadingComponent />
                </div>
            </div>
            <div className={(loading) ? "hidden" : "block md:py-4 md:px-20"}>
                <div className={"flex flex-row justify-between w-full"}>
                    <button className={"bg-white border-1 border-primary rounded-lg lg:hidden py-2 px-4"} onClick={onChangeShow}>
                        <svg xmlns="http://www.w3.org/2000/svg" className={"w-4 h-4"} viewBox="0 0 38 26.333">
                            <g id="Icon_feather-menu" data-name="Icon feather-menu" transform="translate(-3 -7.5)">
                                <path id="Trazado_115" data-name="Trazado 115" d="M4.5,18h35" transform="translate(0 2.667)" fill="none" stroke="#6B0DBC" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                                <path id="Trazado_116" data-name="Trazado 116" d="M4.5,9h35" transform="translate(0)" fill="none" stroke="#6B0DBC" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                                <path id="Trazado_117" data-name="Trazado 117" d="M4.5,27h35" transform="translate(0 5.333)" fill="none" stroke="#6B0DBC" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                            </g>
                        </svg>
                    </button>
                </div>
                <div className={"mt-4 flex flex-col p-8 w-full lg:w-1/2 shadow-custom rounded-lg animate-fade-in"}>
                    <div className={"flex flex-row w-full items-center"}>
                        <button className={"rounded-full hover:bg-gray-50 mr-2"} onClick={() => history.goBack()}>
                            <div className={"w-8 h-8 p-2"}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <path id="Icon_material-arrow_back" data-name="Icon material-arrow_back" d="M30,16.5H11.745L20.13,8.115,18,6,6,18,18,30l2.115-2.115L11.745,19.5H30Z" transform="translate(-6 -6)"/>
                                </svg>
                            </div>
                        </button>
                        {(props.requestType !== "update") ?
                            <p className={"font-bold text-primary text-lg"}>Crear día</p> :
                            <p className={"font-bold text-primary text-lg"}>Actualizar día</p>}
                    </div>

                    <Formik
                        enableReinitialize={true}
                        initialValues={dayState}
                        onSubmit={async values => {
                            setLoading(true);

                            if (props.requestType === "create") {
                                await day.create(values, user?.user?.token as string, user?.user?.userResponse?.name as string + ' '
                                + user?.user?.userResponse?.lastName as string)
                                    .then(() => {
                                        history.goBack();
                                        props.messageNotification('Se ha insertado correctamente');
                                        props.showNotification(true);
                                    })
                                    .catch((error) => checkError(error.toString())).finally(() => setLoading(false))
                            } else if (props.requestType === "update") {
                                await day.put(parseInt(params.id), values, user?.user?.token as string, user?.user?.userResponse?.name as string + ' '
                                + user?.user?.userResponse?.lastName as string)
                                    .then(() => {
                                        history.goBack();
                                        props.messageNotification('Se ha actualizado correctamente');
                                        props.showNotification(true);
                                    })
                                    .catch((error) => checkError(error.toString())).finally(() => setLoading(false));
                            }
                        }}
                        validationSchema={dayValidation}>

                        <Form className={"mt-4"}>
                            <div className={"flex flex-col"}>
                                <label htmlFor={"date"} className={labelStyles}>Fecha*</label>
                                <Field type={"date"} name={"date"} className={inputStyles} />
                                <ErrorMessage name={"date"}>{message =>
                                    <p className={"text-red-500"}>{message}</p>
                                }</ErrorMessage>
                            </div>
                            <div className={"flex flex-col"}>
                                <label htmlFor={"turn"} className={labelStyles}>Turno*</label>
                                <Field type={"text"} name={"turn"} className={inputStyles} />
                                <ErrorMessage name={"turn"}>{message =>
                                    <p className={"text-red-500"}>{message}</p>
                                }</ErrorMessage>
                            </div>
                            <div className={"flex flex-col"}>
                                <label htmlFor={"maxPeople"} className={labelStyles}>Aforo máximo*</label>
                                <Field type={"number"} name={"maxPeople"} className={inputStyles} min={0} />
                                <ErrorMessage name={"maxPeople"}>{message =>
                                    <p className={"text-red-500"}>{message}</p>
                                }</ErrorMessage>
                            </div>
                            <div className={"flex flex-col"}>
                                <label htmlFor={"openHour"} className={labelStyles}>Hora de apertura*</label>
                                <Field type={"time"} name={"openHour"} className={inputStyles} />
                                <ErrorMessage name={"openHour"}>{message =>
                                    <p className={"text-red-500"}>{message}</p>
                                }</ErrorMessage>
                            </div>
                            <div className={"flex flex-col"}>
                                <label htmlFor={"closeHour"} className={labelStyles}>Hora de cierre*</label>
                                <Field type={"time"} name={"closeHour"} className={inputStyles}/>
                                <ErrorMessage name={"closeHour"}>{message =>
                                    <p className={"text-red-500"}>{message}</p>
                                }</ErrorMessage>
                            </div>
                            <input type="submit" value={"Guardar"} className={"bg-primary text-white p-2 rounded-lg mt-2 cursor-pointer"}/>
                        </Form>
                    </Formik>
                </div>
            </div>
        </>
    );
};

export default DayFormScreen;
