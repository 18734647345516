import {ErrorMessage, useFormikContext} from "formik";
import React, {ChangeEvent, useState} from "react";

interface ITextAreaComponent {
    field: string,
    label: string,
    labelStyles?: string,
    inputStyle?: string,
    description: string
    setDescription: any
}

const TextAreaComponent = (props: ITextAreaComponent) => {
    const {values} = useFormikContext<any>();

    const [characterCounter, setCharacterCounter] = useState<any>(0);
    const maxCharacterCount = 150;

    const handleOnChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        values[props.field] = e.currentTarget.value;
        props.setDescription(e.currentTarget.value);
        setCharacterCounter(e.currentTarget.value.length);
    };

    return (
        <div className={"flex flex-col w-full"}>
            <label className={props.labelStyles}>{props.label}</label>
            <div className={"flex flex-col"}>
                {props.description !== null ?
                    <textarea className={props.inputStyle} style={{resize: 'none'}} onChange={handleOnChange} defaultValue={props.description} />
                    : <textarea className={props.inputStyle} style={{resize: 'none'}} onChange={handleOnChange} />
                }
                <div className={"flex flex-row justify-between"}>
                    <ErrorMessage name={"description"}>{message =>
                        <p className={"text-red-500"}>{message}</p>
                    }</ErrorMessage>
                    <p className={((props.description.length > maxCharacterCount) || (characterCounter > maxCharacterCount)) ? "text-right text-red-500" : "text-right text-black"}>{!(props.description.length <= 0) ? props.description.length : characterCounter} / {maxCharacterCount}</p>
                </div>
            </div>

        </div>
    )
};

export default TextAreaComponent;
