import React from "react";
import DashboardRouter from "./DashboardRouter";
import {BrowserRouter as Router, Switch} from "react-router-dom";
import {PrivateRoute} from "./PrivateRoute";
import {PublicRoute} from "./PublicRoute";
import LoginScreen from "../components/login/LoginScreen";

const AppRouter = () => {
    return (
      <Router>
          <div className={"flex flex-row h-screen overflow-y-hidden overflow-x-hidden"}>
              <Switch>
                  <PublicRoute path={"/login"} component={LoginScreen}/>
                  <PrivateRoute path={"/"} component={DashboardRouter}/>
              </Switch>
          </div>
      </Router>
    );
};

export default AppRouter
