import * as Yup from 'yup'

export const userValidation = Yup.object({
    name: Yup.string().required('Este campo es obligatorio'),
    lastName: Yup.string().required('Este campo es obligatorio'),
    userName: Yup.string().required('Este campo es obligatorio')
        .min(4, 'Debe contener 4 caracteres como mínimo'),
    password: Yup.string().required('Este campo es obligatorio')
        .min(4, 'Debe contener 4 caracteres como mínimo'),
    role: Yup.string().required('Este campo es obligatorio')
});

export const userValidationForUpdate = Yup.object({
    name: Yup.string().required('Este campo es obligatorio'),
    lastName: Yup.string().required('Este campo es obligatorio'),
    role: Yup.string().required('Este campo es obligatorio')
});

export const userValidationUsername = Yup.object({
    value: Yup.string().required('Este campo es obligatorio')
        .min(4, 'Debe contener 4 caracteres como mínimo'),
});

export const userValidationPassword = Yup.object({
    value: Yup.string().required('Este campo es obligatorio')
        .min(4, 'Debe contener 4 caracteres como mínimo'),
});
