import {createContext} from "react";
import {IAuthType} from "../types/authType";

interface IAuthContext {
    user?: IAuthType,
    userDispatch?: any
}

const defaultState: IAuthContext = {
    user: {},
    userDispatch: null
};

export const AuthContext = createContext<IAuthContext>(defaultState);
