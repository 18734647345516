import React, {useContext, useEffect, useReducer, useState} from "react";
import { useHistory } from "react-router-dom";
import {AuthContext} from "../../context/AuthContext";
import { ILog } from "../../models/log";
import {logReducer} from "../../reducers/logReducer";
import LoadingComponent from "../ui/LoadingComponent";
import SearchbarComponent from "../ui/SearchbarComponent";
import PaginationComponent from "../ui/PaginationComponent";
import {IWeekmenu} from "../../models/weekmenu";
import ModalComponent from "../ui/ModalComponent";
import log from "../../api/log";

interface ILogListScreen {
    showNotification: any,
    messageNotification: any,
    showSidebar: boolean,
    setShowSidebar: any
}

const LogListScreen = (props: ILogListScreen) => {

    const logInitialState: ILog[] = [];
    const countRecordsPerPages: number = 10;

    const history = useHistory();
    const { user, userDispatch } = useContext(AuthContext);

    const [logState, setLogState] = useReducer(logReducer, logInitialState);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);

    function onChangeShow() {
        if (props.showSidebar)
            props.setShowSidebar(false);
        else
            props.setShowSidebar(true)
    }

    function checkError(error: string) {
        if (error.includes('500')) {
            props.messageNotification('Error interno del servidor, comprueba internet y vuelve a intentarlo luego');
            props.showNotification(true);
        } else if (error.includes('404')) {
            props.messageNotification('El dato que quiere borrar no existe');
            props.showNotification(true);
        } else if (error.includes('400')) {
            props.messageNotification('La operación ha salido mal');
            props.showNotification(true);
        } else if (error.includes('Network Error')) {
            userDispatch({
                user: null,
            });
            history.replace('/login')
        } else {
            props.messageNotification('Error desconocido');
            props.showNotification(true);
        }
    }

    useEffect(() => {
        setLoading(true);
        log.list(user?.user?.token as string, currentPage, countRecordsPerPages)
            .then(response => {
                const totalRecords = response.totalCountRecords;
                setTotalPages(Math.ceil(totalRecords / countRecordsPerPages));

                const action = {
                    type: 'charge',
                    payload: response.log
                };

                setLogState(action)
            })
            .catch((error) => checkError(error.toString()))
            .finally(() => setLoading(false))
    }, [currentPage]);

    return (
        <>
            <div className={(loading) ? 'block w-full h-full flex flex-col justify-center items-center animate-fade-in' : 'hidden'}>
                <div className={"w-40 h-40"}>
                    <LoadingComponent />
                </div>
            </div>
            <div className={ (loading) ? "hidden" : "block h-full w-full md:py-4 md:px-20 lg:w-auto"}>
                <div className={"flex flex-row justify-between w-full"}>
                    <button className={"bg-white border-1 border-primary rounded-lg lg:hidden py-2 px-4"} onClick={onChangeShow}>
                        <svg xmlns="http://www.w3.org/2000/svg" className={"w-4 h-4"} viewBox="0 0 38 26.333">
                            <g id="Icon_feather-menu" data-name="Icon feather-menu" transform="translate(-3 -7.5)">
                                <path id="Trazado_115" data-name="Trazado 115" d="M4.5,18h35" transform="translate(0 2.667)" fill="none" stroke="#6B0DBC" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                                <path id="Trazado_116" data-name="Trazado 116" d="M4.5,9h35" transform="translate(0)" fill="none" stroke="#6B0DBC" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                                <path id="Trazado_117" data-name="Trazado 117" d="M4.5,27h35" transform="translate(0 5.333)" fill="none" stroke="#6B0DBC" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                            </g>
                        </svg>
                    </button>
                    <div className={"hidden md:flex flex-col justify-center items-center"}>
                        <PaginationComponent currentPage={currentPage} totalCountRecords={totalPages} onChange={newPage => setCurrentPage(newPage)}/>
                    </div>
                </div>
                <div className={"block md:hidden w-full h-16 flex flex-row justify-center"}>
                    <PaginationComponent currentPage={currentPage} totalCountRecords={totalPages} onChange={newPage => setCurrentPage(newPage)}/>
                </div>
                <div className="w-full h-full overflow-y-scroll px-2">
                    <div className={"py-8 flex flex-row justify-left flex-wrap animate-fade-in md:gap-2 lg:gap-4"}>
                        {logState.map((value: any) => (
                            value.map((trueValue: ILog) => (
                                <div key={trueValue.id} className={"flex flex-col justify-between w-full p-4 shadow-custom rounded-lg mb-4"}>
                                    <div className={"flex flex-col md:flex-row w-full justify-between"}>
                                        <p className={"font-bold text-primary"}>{trueValue.name}</p>
                                        <p>{trueValue.createdAt}</p>
                                    </div>
                                    <hr/>
                                    <div className={"flex flex-col w-full pt-2"}>
                                        <p className={"font-bold text-indigo-500"}>{trueValue.actionType}</p>
                                        <p>{trueValue.description}</p>
                                    </div>
                                </div>
                            ))
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
};

export default LogListScreen;
