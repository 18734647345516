import React, {useContext, useState} from "react";
import background from "../assets/img/main-aulaga.webp";
import {EyeIcon, EyeOffIcon} from "@heroicons/react/outline";
import {useHistory} from "react-router-dom";
import auth from "../../api/auth";
import {IUserCredentials} from "../../models/user";
import {Formik, Form, Field} from "formik";
import {AuthContext} from "../../context/AuthContext";
import LoadingComponent from "../ui/LoadingComponent";

const LoginScreen = () => {

    const history = useHistory();
    const { userDispatch } = useContext(AuthContext);
    const [checked, setChecked] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const userCredential: IUserCredentials = {
        userName: '',
        password: ''
    };

    function handleAction(): void{
        if (checked)
            setChecked(false);
        else
            setChecked(true);
    }

    function styles(url: string) {
        return {
            backgroundImage: `url(${url})`,
        }
    }

    return (
        <div className={"flex flex-row h-screen w-screen bg-secondary"}>
            <div className={"hidden md:px-8 lg:w-1/2 lg:h-full lg:items-end lg:flex lg:px-24"}>
                <div className={"h-full w-full bg-no-repeat bg-bottom bg-contain"} style={styles(background)}>

                </div>
            </div>
            <div className={(loading) ? 'w-full lg:w-1/2 h-full flex flex-col justify-center items-center animate-fade-in bg-white' : 'hidden'}>
                <div className={"w-56 h-56 lg:w-1/2 lg:h-40"}>
                    <LoadingComponent />
                </div>
            </div>
            <div className={(loading) ? "hidden" : "w-full lg:w-1/2 h-full bg-white flex flex-col justify-center items-center" }>
                <h3 className={"text-primary text-center text-2xl font-bold my-4"}>Aulaga <br/> Administración</h3>
                <h5 className={"text-indigo-500 text-center text-xl w-1/2"}>Inicio de sesión para los alumnos de cocina y restauración</h5>

                <Formik initialValues={userCredential} onSubmit={
                    async values => {
                        setLoading(true);
                        auth.login(values)
                            .then(response => {
                                userDispatch({
                                    user: response,
                                });
                                history.push('/')
                            }).catch((error) => {
                                console.log(error);
                                if (error.toString().includes('404')){
                                    setError("El usuario o la contraseña introducida son incorrecta");
                                } else {
                                    setError("Servidor fuera de servicio");
                                }

                                setLoading(false);
                        });
                    }}>
                    {(formikProps) => (
                        <Form className={"w-1/2 mt-4"}>
                            <Field name={"userName"} className={"w-full rounded-lg bg-gray-50 p-2 border-l-8 border-primary my-2 focus:border-primary-hover"} type={"text"} placeholder={"Nombre de usuario"}/>
                            <div className={"flex flex-row w-full"}>
                                <Field name={"password"} className={"w-full rounded-lg bg-gray-50 p-2 border-l-8 border-primary my-2 focus:border-primary-hover"} type={checked ? "text" : "password"} placeholder={"Contraseña"} />
                                <EyeIcon className={checked ? "hidden" : "h-hull w-10 p-2 text-primary block cursor-pointer"} onClick={ handleAction }/>
                                <EyeOffIcon className={checked ? "h-hull w-10 p-2 text-primary block cursor-pointer" : "hidden"} onClick={ handleAction }/>
                            </div>

                            <p className={(error.length > 0) ? "block text-red-500" : "hidden"}>{error}</p>

                            <input className={"w-full bg-primary p-2 rounded-full text-white my-2 hover:bg-primary-hover cursor-pointer"}
                                   disabled={(formikProps.values.userName.length <= 0 || formikProps.values.password.length <= 0 )} type={"submit"} value={"Entrar"}/>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default LoginScreen;
