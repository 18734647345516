import React from 'react';

interface IModalComponent {
    state: modalState,
    setState: any,
    onConfirm: any,
}

interface modalState {
    show: boolean,
    itemId: any
}

const ModalComponent = (props: React.PropsWithoutRef<IModalComponent>) => {

    return (
        <>
            {props.state.show &&
                <div className={"h-screen w-screen px-2 flex items-center justify-center fixed top-0 left-0 bg-black bg-opacity-50"}>
                    <div className={"w-full h-1/4 md:w-3/4 lg:w-1/3 md:h-1/4 flex flex-col justify-between bg-white opacity-100 shadow-custom rounded-lg p-8"}>
                        <h1 className={"text-xl font-bold"}>Confirmación de acción</h1>
                        <p className={"text-lg"}>¿Estas seguro que quieres borrar este elemento?</p>
                        <div className={"w-full flex flex-row justify-end"}>
                            <button className={"bg-primary mr-4 p-2 rounded-lg text-white"} onClick={() => {
                                props.setState({show: false,});
                                props.onConfirm(props.state.itemId);
                            }}>Borrar</button>
                            <button className={"p-2 rounded-lg"} onClick={() => props.setState({show: false,})}>Cancelar</button>
                        </div>
                    </div>
                </div>
            }
        </>
    );

};

export default ModalComponent;
