import React, {useEffect, useState} from "react";
import NavbarComponent from "../components/ui/NavbarComponent";
import {Route, Redirect, Switch} from "react-router-dom";
import DayListScreen from "../components/day/DayListScreen";
import WeekMenuListScreen from "../components/weekmenu/WeekMenuListScreen";
import PlateListScreen from "../components/plate/PlateListScreen";
import UserListScreen from "../components/user/UserListScreen";
import DayFormScreen from "../components/day/DayFormScreen";
import NotificationComponent from "../components/ui/NotificationComponent";
import DayReservationsAdminScreen from "../components/day/DayReservationsAdminScreen";
import ReservationFormComponent from "../components/reservation/ReservationFormComponent";
import PlateFormScreen from "../components/plate/PlateFormScreen";
import WeekMenuFormScreen from "../components/weekmenu/WeekMenuFormScreen";
import UserFormScreen from "../components/user/UserFormScreen";
import UserSecondFormScreen from "../components/user/UserSecondFormScreen";
import TeamImageForm from "../components/team/TeamImageForm";
import OtherFormScreen from "../components/other/OtherFormScreen";
import DayHistoryListScreen from "../components/day/DayHistoryListScreen";
import LogListScreen from "../components/log/LogListScreen";

const DashboardRouter = () => {
    const [showNotification, setShowNotification] = useState(false);
    const [message, setMessage] = useState('Error');
    const [showSidebar, setShowSidebar] = useState(false);

    useEffect(() => {
        if (showNotification) {
            setTimeout(() => {
                setShowNotification(false);
            }, 3000);
        }
    }, [showNotification]);

    return (
        <>
            <NavbarComponent showSidebar={showSidebar} setShowSidebar={setShowSidebar}/>

            <div className={"w-full py-2 px-8 lg:mx-20 my-9"}>
                <Switch>
                    {/*Day routes*/}
                    <Route exact path={"/day"}>
                        <DayListScreen showNotification={setShowNotification} messageNotification={setMessage} showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
                    </Route>
                    <Route exact path={"/history-days"}>
                        <DayHistoryListScreen showNotification={setShowNotification} messageNotification={setMessage} showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
                    </Route>
                    <Route exact path={"/day/new"}>
                        <DayFormScreen requestType={"create"} showNotification={setShowNotification} messageNotification={setMessage} showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
                    </Route>
                    <Route exact path={"/day/update/:id"}>
                        <DayFormScreen requestType={"update"} showNotification={setShowNotification} messageNotification={setMessage} showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
                    </Route>
                    {/*Day - reservation routes*/}
                    <Route exact path={"/day/reservations/:dayId&:day&:people&:isHistory"}>
                        <DayReservationsAdminScreen showNotification={setShowNotification} messageNotification={setMessage} showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
                    </Route>
                    <Route exact path={"/day/reservations/create/:dayId"}>
                        <ReservationFormComponent requestType={"create"} showNotification={setShowNotification} messageNotification={setMessage} showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
                    </Route>
                    <Route exact path={"/day/reservations/update/:id&:dayId"}>
                        <ReservationFormComponent requestType={"update"} showNotification={setShowNotification} messageNotification={setMessage} showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
                    </Route>
                    {/*Week menu routes*/}
                    <Route exact path={"/week-menus"}>
                        <WeekMenuListScreen showNotification={setShowNotification} messageNotification={setMessage} showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
                    </Route>
                    <Route exact path={"/week-menus/new"}>
                        <WeekMenuFormScreen requestType={"create"} showNotification={setShowNotification} messageNotification={setMessage} showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
                    </Route>
                    <Route exact path={"/week-menus/update/:id"}>
                        <WeekMenuFormScreen requestType={"update"} showNotification={setShowNotification} messageNotification={setMessage} showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
                    </Route>
                    {/*Plates routes*/}
                    <Route exact path={"/plates"}>
                        <PlateListScreen showNotification={setShowNotification} messageNotification={setMessage} showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
                    </Route>
                    <Route exact path={"/plates/new"}>
                        <PlateFormScreen requestType={"create"} showNotification={setShowNotification} messageNotification={setMessage} showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
                    </Route>
                    <Route exact path={"/plates/update/:id"}>
                        <PlateFormScreen requestType={"update"} showNotification={setShowNotification} messageNotification={setMessage} showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
                    </Route>
                    {/*Users routes*/}
                    <Route exact path={"/users"}>
                        <UserListScreen showNotification={setShowNotification} messageNotification={setMessage} showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
                    </Route>
                    <Route exact path={"/users/new"}>
                        <UserFormScreen requestType={"create"} showNotification={setShowNotification} messageNotification={setMessage} showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
                    </Route>
                    <Route exact path={"/users/update/:id"}>
                        <UserFormScreen requestType={"update"} showNotification={setShowNotification} messageNotification={setMessage} showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
                    </Route>
                    <Route exact path={"/users/passuserupdate/:id"}>
                        <UserSecondFormScreen showNotification={setShowNotification} messageNotification={setMessage} showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
                    </Route>
                    <Route exact path={"/team"}>
                        <TeamImageForm showNotification={setShowNotification} messageNotification={setMessage} showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
                    </Route>
                    <Route exact path={"/other"}>
                        <OtherFormScreen showSidebar={showSidebar} setShowSidebar={setShowSidebar} showNotification={setShowNotification} messageNotification={setMessage} />
                    </Route>
                    <Route exact path={"/log"}>
                        <LogListScreen showNotification={setShowNotification} messageNotification={setMessage} showSidebar={showSidebar} setShowSidebar={setShowSidebar}/>
                    </Route>

                    <Redirect exact to={"/day"} from={"/"} />
                </Switch>

                <NotificationComponent visible={showNotification} message={message}/>
            </div>
        </>
    )
};

export default DashboardRouter;
