import React from 'react';
interface INotificationComponent {
    visible?: boolean,
    message: string
}

const NotificationComponent = (props: INotificationComponent) => {
    return (
        <div
            className={"fixed bottom-10 -right-0 bg-secondary p-4 max-w-xs block " +
            ((props.visible) ? 'transition duration-500 easy-all transform -translate-x-0' : 'transition duration-500 easy-all transform translate-x-full')}>
            <p className={"text-white"}>{ props.message }</p>
        </div>
    );

};

export default NotificationComponent;
