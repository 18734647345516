import {Redirect, Route, RouteProps } from "react-router-dom";
import React, {useContext} from "react";
import {AuthContext} from "../context/AuthContext";

interface IPrivateRoute extends RouteProps {}

export const PrivateRoute: React.FC<IPrivateRoute> = ({...rest}) => {
    const context = useContext(AuthContext);

    if (!context.user?.user?.token) return <Redirect to={"/login"} />;
    return (
        <Route {...rest}/>
    )
};
