export const dayReducer = (state: any[] = [], action: {
    type: string,
    payload: any[]
}) => {
    switch (action.type) {
        case 'charge':
            state = [];
            return [ ...state, action.payload ];
        default:
            return state
    }
};
