import React, {useContext, useEffect, useReducer, useState} from 'react';
import SearchbarComponent from "../ui/SearchbarComponent";
import {IUser} from "../../models/user";
import {userReducer} from "../../reducers/userReducer";
import users from "../../api/users";
import {useHistory} from "react-router-dom";
import LoadingComponent from "../ui/LoadingComponent";
import ModalComponent from "../ui/ModalComponent";
import {AuthContext} from "../../context/AuthContext";

interface IUserListScreen {
    showNotification: any,
    messageNotification: any,
    showSidebar: boolean,
    setShowSidebar: any
}

const UserListScreen = (props: IUserListScreen) => {
    const buttonStyles = "h-6 border-1 border-secondary rounded-full mt-2 w-full text-primary text-sm transition duration-300 ease-in-out hover:bg-primary-hover hover:text-white";

    const initialState: IUser[] = [];
    const context = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [confirm, setConfirm] = useState({
        show: false,
        itemId: 0
    });
    const history = useHistory();
    const { user, userDispatch } = useContext(AuthContext);

    const [state, dispatch] = useReducer(userReducer, initialState);

    function onChangeShow() {
        if (props.showSidebar)
            props.setShowSidebar(false);
        else
            props.setShowSidebar(true)
    }

    function checkError(error: string) {
        if (error.includes('500')) {
            props.messageNotification('Error interno del servidor, comprueba internet y vuelve a intentarlo luego');
            props.showNotification(true);
        } else if (error.includes('404')) {
            props.messageNotification('El dato que quiere borrar no existe');
            props.showNotification(true);
        } else if (error.includes('400')) {
            props.messageNotification('La operación ha salido mal');
            props.showNotification(true);
        } else if (error.includes('Network Error')) {
            userDispatch({
                user: null,
            });
            history.replace('/login')
        } else {
            props.messageNotification('Error desconocido');
            props.showNotification(true);
        }
    }

    const onDelete = async (id: number) => {
        users.delete(id, user?.user?.token as string, user?.user?.userResponse?.name as string + ' '
        + user?.user?.userResponse?.lastName as string)
            .then(() => {
                props.messageNotification('Se ha borrado correctamente');
                props.showNotification(true);

                users.list(user?.user?.token as string)
                    .then((response) => {
                        const action = {
                            type: 'charge',
                            payload: response
                        };
                        dispatch(action);
                    })
                    .catch((error) => checkError(error.toString()))
                    .finally(() => setLoading(false));
            }).catch((error) => checkError(error.toString()))
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        setLoading(true);
        users.list(user?.user?.token as string)
            .then(response => {
                const action = {
                    type: 'charge',
                    payload: response
                };
                dispatch(action);
                setLoading(false)
            })
            .catch((error) => checkError(error.toString()))
            .finally(() => setLoading(false));
    }, []);

    return (
        <>
            <div className={(loading) ? 'block w-full h-full flex flex-col justify-center items-center animate-fade-in' : 'hidden'}>
                <div className={"w-40 h-40"}>
                    <LoadingComponent />
                </div>
            </div>
            <div className={ (loading) ? "hidden" : "block h-full w-full md:py-4 md:px-20 lg:w-auto"}>
                <div className={"flex flex-row justify-between w-full"}>
                    <button className={"bg-white border-1 border-primary rounded-lg lg:hidden py-2 px-4"} onClick={onChangeShow}>
                        <svg xmlns="http://www.w3.org/2000/svg" className={"w-4 h-4"} viewBox="0 0 38 26.333">
                            <g id="Icon_feather-menu" data-name="Icon feather-menu" transform="translate(-3 -7.5)">
                                <path id="Trazado_115" data-name="Trazado 115" d="M4.5,18h35" transform="translate(0 2.667)" fill="none" stroke="#6B0DBC" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                                <path id="Trazado_116" data-name="Trazado 116" d="M4.5,9h35" transform="translate(0)" fill="none" stroke="#6B0DBC" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                                <path id="Trazado_117" data-name="Trazado 117" d="M4.5,27h35" transform="translate(0 5.333)" fill="none" stroke="#6B0DBC" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                            </g>
                        </svg>
                    </button>
                    <SearchbarComponent type={"usuarios"} showSearchBar={false} />
                </div>
                <div className="w-full h-full overflow-y-scroll px-2">
                    <div className={"flex flex-row w-full py-8 gap-4 flex-wrap animate-fade-in justify-center md:justify-start place-self-start"}>
                        {state.map((value: any) => (
                            value.map((trueValue: IUser) => (
                                (context.user?.user?.userResponse?.id != trueValue.id) ?
                                    <div key={trueValue.id} className={"flex flex-col w-full md:w-auto h-44 p-4 shadow-custom rounded-lg"}>
                                        <div className={"flex flex-row items-center"}>
                                            <div className={"h-12 w-12 p-2 mr-2 flex flex-row items-center"}>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42.359 48.413">
                                                    <path id="Icon_awesome-user-graduate" data-name="Icon awesome-user-graduate" d="M30.2,30.314l-9.02,9.02-9.02-9.02A12.679,12.679,0,0,0,0,42.965v.908a4.54,4.54,0,0,0,4.539,4.539H37.821a4.54,4.54,0,0,0,4.539-4.539v-.908A12.679,12.679,0,0,0,30.2,30.314ZM1.286,7.546l.605.142V13.21a2.184,2.184,0,0,0-.085,3.782L.331,22.882c-.161.652.2,1.324.719,1.324H5c.52,0,.879-.671.719-1.324L4.245,16.992A2.184,2.184,0,0,0,4.16,13.21V8.236l6.24,1.5a12.081,12.081,0,1,0,22.882,5.389A11.887,11.887,0,0,0,31.959,9.74l9.105-2.194a1.5,1.5,0,0,0,0-2.978l-18-4.349a8.122,8.122,0,0,0-3.754,0L1.286,4.558a1.511,1.511,0,0,0,0,2.988Z" transform="translate(0 0.002)" fill="#6B0DBC"/>
                                                </svg>
                                            </div>
                                            <div className={"flex flex-col"}>
                                                <p className={"font-bold"}>{trueValue.name}</p>
                                                <p>{trueValue.lastName}</p>
                                            </div>
                                        </div>

                                        <button className={buttonStyles + " px-4"} onClick={() => history.push(`/users/passuserupdate/${trueValue.id}`)}>Nombre o contraseña</button>
                                        <button className={buttonStyles} onClick={() => history.push(`/users/update/${trueValue.id}`)}>Actualizar</button>
                                        <button className={buttonStyles} onClick={() => {
                                            setConfirm({
                                                show: true,
                                                itemId: trueValue.id
                                            })
                                        }}>Borrar</button>
                                    </div> : <></>
                            ))
                        ))}
                    </div>
                </div>
            </div>

            <ModalComponent state={confirm} setState={setConfirm} onConfirm={onDelete}/>
        </>
    );
};

export default UserListScreen;
