import React, {useContext, useEffect, useReducer, useState} from 'react';
import SearchbarComponent from "../ui/SearchbarComponent";
import {IWeekmenu} from "../../models/weekmenu";
import {weekMenuReducer} from "../../reducers/weekMenuReducer";
import weekMenu from "../../api/weekMenu";
import { useHistory } from 'react-router-dom';
import LoadingComponent from "../ui/LoadingComponent";
import ModalComponent from "../ui/ModalComponent";
import {AuthContext} from "../../context/AuthContext";
import PaginationComponent from "../ui/PaginationComponent";

interface IWeekMenuListScreen {
    showNotification: any,
    messageNotification: any,
    showSidebar: boolean,
    setShowSidebar: any
}

const WeekMenuListScreen = (props: IWeekMenuListScreen) => {
    const buttonStyles = "h-6 border-1 border-secondary rounded-full mt-2 w-full text-primary text-sm transition duration-300 ease-in-out hover:bg-primary-hover hover:text-white";
    const textStyles = "text-indigo-500 font-bold text-sm";

    const initialState: IWeekmenu[] = [];

    const history = useHistory();
    const { user, userDispatch } = useContext(AuthContext);

    const [state, dispatch] = useReducer(weekMenuReducer, initialState);
    const [loading, setLoading] = useState(true);
    const [confirm, setConfirm] = useState({
        show: false,
        itemId: 0
    });

    const [totalPages, setTotalPages] = useState(0);
    const [countRecordsPerPages, setCountRecordsPerPages] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    function onChangeShow() {
        if (props.showSidebar)
            props.setShowSidebar(false);
        else
            props.setShowSidebar(true)
    }

    function checkError(error: string) {
        if (error.includes('500')) {
            props.messageNotification('Error interno del servidor, comprueba internet y vuelve a intentarlo luego');
            props.showNotification(true);
        } else if (error.includes('404')) {
            props.messageNotification('El dato que quiere borrar no existe');
            props.showNotification(true);
        } else if (error.includes('400')) {
            props.messageNotification('La operación ha salido mal');
            props.showNotification(true);
        } else if (error.includes('Network Error')) {
            userDispatch({
                user: null,
            });
            history.replace('/login')
        } else {
            props.messageNotification('Error desconocido');
            props.showNotification(true);
        }
    }

    const onDelete = async (id: number) => {
        setLoading(true);
        weekMenu.delete(id, user?.user?.token as string, user?.user?.userResponse?.name as string + ' '
        + user?.user?.userResponse?.lastName as string)
            .then(() => {
                props.messageNotification('Se ha borrado correctamente');
                props.showNotification(true);

                weekMenu.list(user?.user?.token as string, currentPage, countRecordsPerPages)
                    .then(response => {
                        const totalRecords = response.totalCountRecords;
                        setTotalPages(Math.ceil(totalRecords / countRecordsPerPages));

                        const action = {
                            type: 'charge',
                            payload: response.weekMenu
                        };

                        dispatch(action);
                    }).catch((error) => checkError(error.toString()))
                    .finally(() => setLoading(false));
            })
            .catch((error) => checkError(error.toString()))
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        setLoading(true);
        weekMenu.list(user?.user?.token as string, currentPage, countRecordsPerPages)
            .then(response => {
                const totalRecords = response.totalCountRecords;
                setTotalPages(Math.ceil(totalRecords / countRecordsPerPages));

                const action = {
                    type: 'charge',
                    payload: response.weekMenu
                };

                dispatch(action);
            })
            .catch((error) => checkError(error.toString()))
            .finally(() => setLoading(false))
    }, [currentPage, countRecordsPerPages]);

    return (
        <>
            <div className={(loading) ? 'block w-full h-full flex flex-col justify-center items-center animate-fade-in' : 'hidden'}>
                <div className={"w-40 h-40"}>
                    <LoadingComponent />
                </div>
            </div>
            <div className={ (loading) ? "hidden" : "block h-full w-full md:py-4 md:px-20 lg:w-auto"}>
                <div className={"flex flex-row justify-between w-full"}>
                    <button className={"bg-white border-1 border-primary rounded-lg lg:hidden py-2 px-4"} onClick={onChangeShow}>
                        <svg xmlns="http://www.w3.org/2000/svg" className={"w-4 h-4"} viewBox="0 0 38 26.333">
                            <g id="Icon_feather-menu" data-name="Icon feather-menu" transform="translate(-3 -7.5)">
                                <path id="Trazado_115" data-name="Trazado 115" d="M4.5,18h35" transform="translate(0 2.667)" fill="none" stroke="#6B0DBC" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                                <path id="Trazado_116" data-name="Trazado 116" d="M4.5,9h35" transform="translate(0)" fill="none" stroke="#6B0DBC" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                                <path id="Trazado_117" data-name="Trazado 117" d="M4.5,27h35" transform="translate(0 5.333)" fill="none" stroke="#6B0DBC" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                            </g>
                        </svg>
                    </button>
                    <SearchbarComponent type={"menú semanal"} showSearchBar={false} />
                    <div className={"hidden md:flex flex-col justify-center items-center"}>
                        <PaginationComponent currentPage={currentPage} totalCountRecords={totalPages} onChange={newPage => setCurrentPage(newPage)}/>
                    </div>
                </div>
                <div className={"block md:hidden w-full h-16 flex flex-row justify-center"}>
                    <PaginationComponent currentPage={currentPage} totalCountRecords={totalPages} onChange={newPage => setCurrentPage(newPage)}/>
                </div>
                <div className="w-full h-full overflow-y-scroll px-2">
                    <div className={"py-8 flex flex-row justify-left flex-wrap animate-fade-in md:gap-2 lg:gap-4"}>
                        {state.map((value: any) => (
                            value.map((trueValue: IWeekmenu) => (
                                <div key={trueValue.id} className={"flex flex-col w-full md:w-auto h-56 p-4 shadow-custom rounded-lg mb-4"}>
                                    <div className={"flex flex-row items-center"}>
                                        <div className={"h-10 w-10 lg:h-12 lg:w-12 mr-2 flex flex-row items-center"}>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55.647 48.78">
                                                <path id="Icon_map-food" data-name="Icon map-food" d="M56.244,15.845,51.682,51.6H36.274L31.73,15.666H50.541L54.259,2.825l2.108.635L52.8,15.818l3.444.027ZM29.552,36.534s.576-4.637-7.414-4.637H9.414C1.435,31.9,2,36.534,2,36.534H29.552ZM2,46.967S1.435,51.6,9.414,51.6H22.139c7.99,0,7.414-4.637,7.414-4.637Zm26.357-2.319a2.666,2.666,0,0,0,2.364-2.9,2.663,2.663,0,0,0-2.364-2.9H3.085a2.668,2.668,0,0,0-2.365,2.9,2.671,2.671,0,0,0,2.365,2.9H28.357Z" transform="translate(-0.72 -2.825)" fill="#6B0DBC"/>
                                            </svg>
                                        </div>
                                        <p className={"font-bold text-sm lg:text-base"}>{trueValue.name}</p>
                                    </div>
                                    <p><span className={textStyles}>Fecha inicio: </span> {trueValue.initDate} </p>
                                    <p><span className={textStyles}>Fecha fin: </span> {trueValue.finishDate} </p>
                                    <p><span className={textStyles}>Número de platos: </span> {trueValue.platesCount} </p>

                                    <button className={buttonStyles} onClick={() => history.push(`/week-menus/update/${trueValue.id}`)}>Actualizar</button>
                                    <button className={buttonStyles} onClick={() =>{
                                        setConfirm({
                                            show: true,
                                            itemId: trueValue.id
                                        })
                                    }}>Borrar</button>
                                </div>
                            ))
                        ))}
                    </div>
                </div>
            </div>

            <ModalComponent state={confirm} setState={setConfirm} onConfirm={onDelete}/>
        </>
    );
};

export default WeekMenuListScreen;
