import {IPlatesNames} from "../../models/plate";
import React, {ChangeEvent, useEffect} from "react";

interface IMultipleSelector {
    items?: IPlatesNames[],
    selected: any[],
    setSelected: any,
    plateses: [{plate: {id: number, name: string, type: string}}],
}

const MultipleSelector = (props: IMultipleSelector) => {

    function mapping(array: {plate: {id: number, name: string}}[]): any[] {
        return array.map(value => {
            return value.plate.id.toString()
        })
    }

    const handleOnChange = (itemId: string, e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            props.setSelected([...props.selected, itemId])
        } else {
            props.setSelected(props.selected.filter((value: any) => value !== itemId))
        }
    };

    useEffect(() => {
        console.log(props.plateses.length);
        if (!props.plateses.find((value) => value.plate.id === 0)) {
            props.setSelected(mapping(props.plateses))
        }
    }, [props.plateses]);

    return (
        <div className={"flex flex-col w-full md:w-1/3 h-96 mt-2 md:mt-0 md:ml-2 p-8 shadow-custom rounded-lg "}>
            <p className={"font-bold text-primary text-lg mb-2"}>Platos</p>
            <div className={"h-full overflow-y-scroll"}>
                {props.items?.map((value: IPlatesNames) =>(
                    <div key={value.id} className={"flex flex-row items-center border-2 border-primary rounded-lg p-2 mb-2"}>
                        <input className={"mr-2"} checked={(props.selected.find((filterValue: string) => parseInt(filterValue) === value.id)) ? true : undefined}
                               type="checkbox" onChange={(e) => handleOnChange(String(value.id), e)}/>
                        <p>{value.name} - {value.type}</p>
                    </div>
                ))}
            </div>
        </div>
    )
};

export default MultipleSelector;
