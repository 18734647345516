import {Form, Formik} from "formik";import React, {useContext, useState} from "react";
import LoadingComponent from "../ui/LoadingComponent";
import ImageSelector from "../ui/ImageSelector";
import other from "../../api/other";
import {AuthContext} from "../../context/AuthContext";
import { useHistory } from "react-router-dom";

interface ITeamImageForm {
    showNotification?: any,
    messageNotification?: any,
    showSidebar: boolean,
    setShowSidebar: any
}

const TeamImageForm = (props: ITeamImageForm) => {
    const [loading, setLoading] = useState(false);

    const { user, userDispatch } = useContext(AuthContext);

    const history = useHistory();

    function onChangeShow() {
        if (props.showSidebar)
            props.setShowSidebar(false);
        else
            props.setShowSidebar(true)
    }

    function checkError(error: string) {
        if (error.includes('500')) {
            props.messageNotification('Error interno del servidor, comprueba internet y vuelve a intentarlo luego');
            props.showNotification(true);
        } else if (error.includes('404')) {
            props.messageNotification('El dato que quiere borrar no existe');
            props.showNotification(true);
        } else if (error.includes('400')) {
            props.messageNotification('La operación ha salido mal');
            props.showNotification(true);
        } else if (error.includes('Network Error')) {
            userDispatch({
                user: null,
            });
            history.replace('/login')
        } else {
            props.messageNotification('Error desconocido');
            props.showNotification(true);
        }
    }

    function onSubmit(field: string, value: any) {
        setLoading(true);
        let formData = new FormData();
        formData.append(field, value);
        other.putImage(formData, user?.user?.token as string, user?.user?.userResponse?.name as string + ' '
        + user?.user?.userResponse?.lastName as string).then(() => {
            setLoading(false);
            props.messageNotification('Se ha actualizado la imagen');
            props.showNotification(true)
        }).catch((error) => checkError(error.toString()))
        .finally(() => setLoading(false));
    }

    return (
        <>
            <div className={(loading) ? 'block w-full h-full flex flex-col justify-center items-center animate-fade-in ' : 'hidden'}>
                <div className={"w-40 h-40"}>
                    <LoadingComponent />
                </div>
            </div>
            <div className={(loading) ? "hidden" : "block md:py-4 md:px-20 w-full h-full"}>
                <div className={"flex flex-row justify-between w-full"}>
                    <button className={"bg-white border-1 border-primary rounded-lg lg:hidden py-2 px-4"} onClick={onChangeShow}>
                        <svg xmlns="http://www.w3.org/2000/svg" className={"w-4 h-4"} viewBox="0 0 38 26.333">
                            <g id="Icon_feather-menu" data-name="Icon feather-menu" transform="translate(-3 -7.5)">
                                <path id="Trazado_115" data-name="Trazado 115" d="M4.5,18h35" transform="translate(0 2.667)" fill="none" stroke="#6B0DBC" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                                <path id="Trazado_116" data-name="Trazado 116" d="M4.5,9h35" transform="translate(0)" fill="none" stroke="#6B0DBC" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                                <path id="Trazado_117" data-name="Trazado 117" d="M4.5,27h35" transform="translate(0 5.333)" fill="none" stroke="#6B0DBC" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                            </g>
                        </svg>
                    </button>
                </div>

                <div className={"w-full h-full overflow-y-scroll p-2"}>
                    <div className="flex flex-row w-full flex-wrap gap-4">
                        <div className={"mt-4 flex flex-col p-8 w-full lg:w-3/4 2xl:w-2/5 shadow-custom rounded-lg animate-fade-in"}>
                            <Formik initialValues={{firstImage: ''}} onSubmit={values => {
                                onSubmit("firstImage", values.firstImage);}}>
                                <Form className={"flex flex-col justify-end"}>
                                    <ImageSelector field={"firstImage"} label={"Primera imagen"}/>
                                    <input type="submit" value={"Guardar"} className={"bg-primary text-white p-2 rounded-lg mt-2 cursor-pointer"}/>
                                </Form>
                            </Formik>
                        </div>
                        <div className={"mt-4 flex flex-col p-8 w-full lg:w-3/4 2xl:w-2/5 shadow-custom rounded-lg animate-fade-in"}>
                            <Formik initialValues={{secondImage: ''}} onSubmit={values => {
                                onSubmit("secondImage", values.secondImage)
                            }}>
                                <Form className={"flex flex-col justify-end"}>
                                    <ImageSelector field={"secondImage"} label={"Segunda imagen"}/>
                                    <input type="submit" value={"Guardar"} className={"bg-primary text-white p-2 rounded-lg mt-2 cursor-pointer"}/>
                                </Form>
                            </Formik>
                        </div>
                        <div className={"mt-4 flex flex-col p-8 w-full lg:w-3/4 2xl:w-2/5 shadow-custom rounded-lg animate-fade-in"}>
                            <Formik initialValues={{thirdImage: ''}} onSubmit={values => {
                                onSubmit("thirdImage", values.thirdImage)
                            }}>
                                <Form className={"flex flex-col justify-end"}>
                                    <ImageSelector field={"thirdImage"} label={"Tercera imagen"}/>
                                    <input type="submit" value={"Guardar"} className={"bg-primary text-white p-2 rounded-lg mt-2 cursor-pointer"}/>
                                </Form>
                            </Formik>
                        </div>
                        <div className={"mt-4 flex flex-col p-8 w-full lg:w-3/4 2xl:w-2/5 shadow-custom rounded-lg animate-fade-in"}>
                            <Formik initialValues={{quarkImage: ''}} onSubmit={values => {
                                onSubmit("quarkImage", values.quarkImage)
                            }}>
                                <Form className={"flex flex-col justify-end"}>
                                    <ImageSelector field={"quarkImage"} label={"Cuarta imagen"}/>
                                    <input type="submit" value={"Guardar"} className={"bg-primary text-white p-2 rounded-lg mt-2 cursor-pointer"}/>
                                </Form>
                            </Formik>
                        </div>
                        <div className={"mt-4 flex flex-col p-8 w-full lg:w-3/4 2xl:w-2/5 shadow-custom rounded-lg animate-fade-in"}>
                            <Formik initialValues={{fifthImage: ''}} onSubmit={values => {
                                onSubmit("fifthImage", values.fifthImage)
                            }}>
                                <Form className={"flex flex-col justify-end"}>
                                    <ImageSelector field={"fifthImage"} label={"Quinta imagen"}/>
                                    <input type="submit" value={"Guardar"} className={"bg-primary text-white p-2 rounded-lg mt-2 cursor-pointer"}/>
                                </Form>
                            </Formik>
                        </div>
                        <div className={"mt-4 flex flex-col p-8 w-full lg:w-3/4 2xl:w-2/5 shadow-custom rounded-lg animate-fade-in"}>
                            <Formik initialValues={{sixthImage: ''}} onSubmit={values => {
                                onSubmit("sixthImage", values.sixthImage)
                            }}>
                                <Form className={"flex flex-col justify-end"}>
                                    <ImageSelector field={"sixthImage"} label={"Sexta imagen"}/>
                                    <input type="submit" value={"Guardar"} className={"bg-primary text-white p-2 rounded-lg mt-2 cursor-pointer"}/>
                                </Form>
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default TeamImageForm;
