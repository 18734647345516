import React, {useEffect, useState} from 'react';
import AppRouter from "./routers/AppRouter";
import {AuthContext} from "./context/AuthContext";
import {IAuthType} from "./types/authType";

function App() {
  const init = () :IAuthType => {
      return {
          user: {},
      }
  };

  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user') as string) || init);

  useEffect(() => {
      localStorage.setItem('user', JSON.stringify(user));
  }, [user]);

  return (
      <AuthContext.Provider value={{ user, userDispatch:setUser }}>
        <AppRouter />
      </AuthContext.Provider>
  );
}

export default App;
