import * as Yup from 'yup'

const numRegularExpresion = /^[0-9]/;

export const plateValidation = Yup.object({
    name: Yup.string().required('Este campo es obligatorio'),
    description: Yup.string().required('Este campo es obligatorio')
        .max(150, 'Ha excedido el límite del campo'),
    type: Yup.string().required('Este campo es obligatorio'),
    price: Yup.string().required('Este campo es obligatorio')
        .matches(numRegularExpresion, 'Contiene caracteres invalidos')
});
