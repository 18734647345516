import React, {useContext, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import LoadingComponent from "../ui/LoadingComponent";
import {ErrorMessage, Field, Form, Formik} from "formik";
import users from "../../api/users";
import {EyeIcon, EyeOffIcon} from "@heroicons/react/outline";
import {userValidationPassword, userValidationUsername} from "../../validation/userValidation";
import {AuthContext} from "../../context/AuthContext";

interface IUserSecondFormScreen {
    showNotification?: any,
    messageNotification?: any,
    showSidebar: boolean,
    setShowSidebar: any
}

interface IParams {
    id: string
}

const UserSecondFormScreen = (props: IUserSecondFormScreen) => {
    const labelStyles = "font-bold mb-2";
    const inputStyles = "border-1 border-primary rounded-lg p-1 mb-2";

    const userRequest: any = {
        "op": "replace",
        "path": "/username",
        "value": ""
    };

    const passwordRequest: any = {
        "op": "replace",
        "path": "/password",
        "value": ""
    };

    const history = useHistory();
    const params = useParams<IParams>();
    const { user, userDispatch } = useContext(AuthContext);

    const [loading, setLoading] = useState(false);
    const [checked, setChecked] = useState(false);

    function handleAction(): void{
        if (checked)
            setChecked(false);
        else
            setChecked(true);
    }

    function onChangeShow() {
        if (props.showSidebar)
            props.setShowSidebar(false);
        else
            props.setShowSidebar(true)
    }

    function checkError(error: string) {
        if (error.includes('500')) {
            props.messageNotification('Error interno del servidor, comprueba internet y vuelve a intentarlo luego');
            props.showNotification(true);
        } else if (error.includes('404')) {
            props.messageNotification('El dato que quiere borrar no existe');
            props.showNotification(true);
        } else if (error.includes('400')) {
            props.messageNotification('La operación ha salido mal');
            props.showNotification(true);
        } else if (error.includes('Network Error')) {
            userDispatch({
                user: null,
            });
            history.replace('/login')
        } else {
            props.messageNotification('Error desconocido');
            props.showNotification(true);
        }
    }

    return (
        <>
            <div className={(loading) ? 'block w-full h-full flex flex-col justify-center items-center animate-fade-in ' : 'hidden'}>
                <div className={"w-40 h-40"}>
                    <LoadingComponent />
                </div>
            </div>

            <div className={(loading) ? "hidden" : "block md:py-4 md:px-20 flex flex-col animate-fade-in"}>
                <div className={"flex flex-row justify-between w-full"}>
                    <button className={"bg-white border-1 border-primary rounded-lg lg:hidden py-2 px-4"} onClick={onChangeShow}>
                        <svg xmlns="http://www.w3.org/2000/svg" className={"w-4 h-4"} viewBox="0 0 38 26.333">
                            <g id="Icon_feather-menu" data-name="Icon feather-menu" transform="translate(-3 -7.5)">
                                <path id="Trazado_115" data-name="Trazado 115" d="M4.5,18h35" transform="translate(0 2.667)" fill="none" stroke="#6B0DBC" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                                <path id="Trazado_116" data-name="Trazado 116" d="M4.5,9h35" transform="translate(0)" fill="none" stroke="#6B0DBC" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                                <path id="Trazado_117" data-name="Trazado 117" d="M4.5,27h35" transform="translate(0 5.333)" fill="none" stroke="#6B0DBC" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                            </g>
                        </svg>
                    </button>
                </div>

                <div className={"h-full w-full overflow-y-scroll p-2"}>
                    <div className={"flex flex-col p-8 w-full lg:w-1/2 shadow-custom rounded-lg my-4"}>
                        <div className="flex flex-row w-full items-center">
                            <button className={"rounded-full hover:bg-gray-50 mr-2"} onClick={() => history.goBack()}>
                                <div className={"w-8 h-8 p-2"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                        <path id="Icon_material-arrow_back" data-name="Icon material-arrow_back" d="M30,16.5H11.745L20.13,8.115,18,6,6,18,18,30l2.115-2.115L11.745,19.5H30Z" transform="translate(-6 -6)"/>
                                    </svg>
                                </div>
                            </button>

                            <p className={"font-bold text-primary text-lg"}>Actualizar nombre de usuario</p>
                        </div>
                        <Formik enableReinitialize={true} initialValues={userRequest} onSubmit={async values => {
                            setLoading(true);

                            users.patchUsername(parseInt(params.id), [values], user?.user?.token as string, user?.user?.userResponse?.name as string + ' '
                            + user?.user?.userResponse?.lastName as string)
                                .then(() => {
                                    props.messageNotification('Se ha actualizado el nombre de usuario correctamente');
                                    props.showNotification(true);
                                    history.goBack();
                                })
                                .catch((error) => checkError(error.toString()))
                                .finally(() => setLoading(false));
                        }} validationSchema={userValidationUsername}>
                            <Form className={"mt-4"}>
                                <div className={"flex flex-col"}>
                                    <label htmlFor={"value"} className={labelStyles}>Nombre de usuario*</label>
                                    <Field type={"text"} name={"value"} className={inputStyles} />

                                    <ErrorMessage name={"value"}>{message =>
                                        <p className={"text-red-500"}>{message}</p>
                                    }</ErrorMessage>
                                </div>

                                <input type="submit" value={"Guardar"} className={"bg-primary text-white p-2 rounded-lg mt-2 cursor-pointer"}/>
                            </Form>
                        </Formik>
                    </div>

                    <div className={"flex flex-col p-8 w-full lg:w-1/2 shadow-custom rounded-lg"}>
                        <div className="flex flex-row w-full items-center">
                            <p className={"font-bold text-primary text-lg"}>Actualizar contraseña</p>
                        </div>
                        <Formik enableReinitialize={true} initialValues={passwordRequest} onSubmit={async value => {
                            setLoading(true);

                            users.patchPassword(parseInt(params.id), [value], user?.user?.token as string, user?.user?.userResponse?.name as string + ' '
                            + user?.user?.userResponse?.lastName as string)
                                .then(() => {
                                    props.messageNotification('Se ha actualizado la contraseña correctamente');
                                    props.showNotification(true);
                                    history.goBack();
                                })
                                .catch((error) => {
                                    props.messageNotification('No se ha podido actualizar la contraseña - ' + error);
                                    props.showNotification(true);
                                })
                                .finally(() => setLoading(false));
                        }} validationSchema={userValidationPassword}>
                            <Form className={"mt-4"}>
                                <div className={"flex flex-col"}>
                                    <label htmlFor={"value"} className={labelStyles}>Contraseña*</label>
                                    <div className={"flex flex-row w-full"}>
                                        <Field name={"value"} className={inputStyles + " w-full"} type={checked ? "text" : "password"} />
                                        <EyeIcon className={checked ? "hidden" : "h-hull w-9 p-2 text-primary block cursor-pointer mb-2"} onClick={ handleAction }/>
                                        <EyeOffIcon className={checked ? "h-hull w-9 p-2 text-primary block cursor-pointer mb-2" : "hidden"} onClick={ handleAction }/>
                                    </div>
                                    <ErrorMessage name={"value"}>{message =>
                                        <p className={"text-red-500"}>{message}</p>
                                    }</ErrorMessage>
                                </div>

                                <input type="submit" value={"Guardar"} className={"bg-primary text-white p-2 rounded-lg mt-2 cursor-pointer"}/>
                            </Form>
                        </Formik>
                    </div>
                </div>
            </div>
        </>
    )
};

export default UserSecondFormScreen;
