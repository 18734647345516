import React, {ChangeEvent, useState} from "react";
import {useFormikContext} from "formik";
import defaultImg from "../assets/img/default.webp";

interface IImagePickerComponent {
    field: string,
    label: string,
    urlImage?: string
}

const ImagePickerComponent = (props: IImagePickerComponent) => {
    const [imageBase64, setImageBase64] = useState('');
    const [imageUrl, setImageUrl] = useState(props.urlImage);
    const { values } = useFormikContext<any>();

    function styles(url: any) {
        return {
            backgroundImage: `url(${url})`,
        }
    }

    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.currentTarget.files) {
            const file = e.currentTarget.files[0];
            toBase64(file)
                .then((value: string) => setImageBase64(value))
                .catch(error => console.error(error));

            values[props.field] = file;
            setImageUrl('');
        }
    };

    const toBase64 = (file: File) => {
        return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = (error) => reject(error);
        })
    };

    return (
        <div className={"flex flex-col w-full md:w-1/3 items-center"}>
            {imageBase64 || props.urlImage ?
                <div className={"h-32 w-32 bg-cover p-2 bg-center bg-no-repeat rounded-lg"} style={styles(imageBase64 || props.urlImage)}/>
                : <div className={"h-32 w-32 bg-cover p-2 bg-center bg-no-repeat rounded-lg"} style={styles(defaultImg)}/>
            }
            <input className={"text-white my-2 text-xs w-32 md:w-32"} type="file" onChange={handleOnChange} accept={"image/*"}/>
        </div>
    )
};

export default ImagePickerComponent;
