import {ErrorMessage, useFormikContext} from "formik";
import React, {ChangeEvent} from "react";

interface INormalTextareaComponent {
    field: string,
    label: string,
    labelStyles?: string,
    inputStyle?: string,
    description: string
    setDescription: any
}

const NormalTextareaComponent = (props: INormalTextareaComponent) => {
    const {values} = useFormikContext<any>();

    const handleOnChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        values[props.field] = e.currentTarget.value;
        props.setDescription(e.currentTarget.value);
    };

    return (
        <div className={"flex flex-col w-full"}>
            <label className={props.labelStyles}>{props.label}</label>
            <div className={"flex flex-col"}>
                {props.description !== null ?
                    <textarea className={props.inputStyle} style={{}} onChange={handleOnChange} defaultValue={props.description} />
                    : <textarea className={props.inputStyle} style={{}} onChange={handleOnChange} />
                }
                <div className={"flex flex-row justify-between"}>
                    <ErrorMessage name={"description"}>{message =>
                        <p className={"text-red-500"}>{message}</p>
                    }</ErrorMessage>
                </div>
            </div>

        </div>
    )
};

export default NormalTextareaComponent;
