import {useHistory, useParams} from "react-router-dom";
import React, {useContext, useEffect, useRef, useState} from "react";
import LoadingComponent from "../ui/LoadingComponent";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {IPlateForCreation} from "../../models/plate";
import plate from "../../api/plate";
import ImagePickerComponent from "../ui/ImagePickerComponent";
import TextAreaComponent from "../ui/TextAreaComponent";
import {plateValidation} from "../../validation/plateValidation";
import {AuthContext} from "../../context/AuthContext";
import JoditEditor from "jodit-react";
import { Interweave } from "interweave";

interface IPlateFormScreen {
    showNotification?: any,
    messageNotification?: any,
    requestType: string,
    showSidebar: boolean,
    setShowSidebar: any
}

interface IParams {
    id: string
}

const PlateFormScreen = (props: IPlateFormScreen) => {

    const labelStyles = "font-bold mb-2";
    const inputStyles = "border-1 border-primary rounded-lg p-1 mb-2";

    const initialState: IPlateForCreation = {
        name: "",
        description: "",
        type: "",
        image: undefined,
        price: "",
        recipe: ''
    };

    const options: any[] = [
        {
            name: 'Selecciona un tipo',
            value: ''
        },
        {
            name: 'Aperitivo',
            value: 'Aperitivo'
        },
        {
            name: 'Principal',
            value: 'Principal'
        },
        {
            name: 'Entrante',
            value: 'Entrante'
        },
        {
            name: 'Primer plato',
            value: 'Primer plato'
        },
        {
            name: 'Segundo plato',
            value: 'Segundo plato'
        },
        {
            name: 'Tercer plato',
            value: 'Tercer plato'
        },
        {
            name: 'Cuarto plato',
            value: 'Cuarto plato'
        },
        {
            name: 'Postre',
            value: 'Postre'
        }
    ];

    const history = useHistory();
    const params = useParams<IParams>();
    const { user, userDispatch } = useContext(AuthContext);
    const editor = useRef(null);

    const [loading, setLoading] = useState(false);
    const [plateState, setPlateState] = useState<IPlateForCreation>(initialState);
    const [description, setDescription] = useState(initialState.description);

    const config = {
        width: "600px",
        height: "450px"
    };

    function onChangeShow() {
        if (props.showSidebar)
            props.setShowSidebar(false);
        else
            props.setShowSidebar(true)
    }

    function checkError(error: string) {
        if (error.includes('500')) {
            props.messageNotification('Error interno del servidor, comprueba internet y vuelve a intentarlo luego');
            props.showNotification(true);
        } else if (error.includes('404')) {
            props.messageNotification('El dato que quiere borrar no existe');
            props.showNotification(true);
        } else if (error.includes('400')) {
            props.messageNotification('La operación ha salido mal');
            props.showNotification(true);
        } else if (error.includes('Network Error')) {
            userDispatch({
                user: null,
            });
            history.replace('/login')
        } else {
            props.messageNotification('Error desconocido');
            props.showNotification(true);
        }
    }

    useEffect(() => {
        if (props.requestType === "update") {
            setLoading(true);
            plate.get(parseInt(params.id), user?.user?.token as string)
                .then((response) => setPlateState(response))
                .catch((error) => checkError(error.toString()))
                .finally(() => setLoading(false))
        }
    }, []);

    return (
        <>
            <div className={(loading) ? 'block w-full h-full flex flex-col justify-center items-center animate-fade-in ' : 'hidden'}>
                <div className={"w-40 h-40"}>
                    <LoadingComponent />
                </div>
            </div>
            <div className={(loading) ? "hidden" : "block md:py-4 md:px-20 w-full h-full"}>
                <div className={"flex flex-row justify-between w-full"}>
                    <button className={"bg-white border-1 border-primary rounded-lg lg:hidden py-2 px-4"} onClick={onChangeShow}>
                        <svg xmlns="http://www.w3.org/2000/svg" className={"w-4 h-4"} viewBox="0 0 38 26.333">
                            <g id="Icon_feather-menu" data-name="Icon feather-menu" transform="translate(-3 -7.5)">
                                <path id="Trazado_115" data-name="Trazado 115" d="M4.5,18h35" transform="translate(0 2.667)" fill="none" stroke="#6B0DBC" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                                <path id="Trazado_116" data-name="Trazado 116" d="M4.5,9h35" transform="translate(0)" fill="none" stroke="#6B0DBC" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                                <path id="Trazado_117" data-name="Trazado 117" d="M4.5,27h35" transform="translate(0 5.333)" fill="none" stroke="#6B0DBC" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                            </g>
                        </svg>
                    </button>
                </div>

                <div className={"w-full h-full overflow-y-scroll p-2"}>
                        <Formik enableReinitialize={true} initialValues={plateState} onSubmit={values => {
                            setLoading(true);

                            let formData = new FormData();
                            formData.append("name", values.name);
                            formData.append("description", values.description);
                            formData.append("type", values.type);
                            formData.append("image", values.image);
                            formData.append("price", values.price);
                            formData.append("recipe", values.recipe);

                            if (props.requestType === "create") {
                                plate.create(formData, user?.user?.token as string, user?.user?.userResponse?.name as string + ' '
                                + user?.user?.userResponse?.lastName as string)
                                    .then(() => {
                                        setLoading(false);
                                        history.goBack();
                                        props.messageNotification('Se ha insertado correctamente');
                                        props.showNotification(true);
                                    })
                                    .catch((error) => checkError(error.toString())).finally(() => setLoading(false))
                            } else if (props.requestType === "update"){
                                plate.put(parseInt(params.id), formData, user?.user?.token as string, user?.user?.userResponse?.name as string + ' '
                                + user?.user?.userResponse?.lastName as string)
                                    .then(() => {
                                        setLoading(false);
                                        history.goBack();
                                        props.messageNotification('Se ha actualizado correctamente');
                                        props.showNotification(true);
                                    })
                                    .catch((error) => checkError(error.toString())).finally(() => setLoading(false))
                            }
                        }} validationSchema={plateValidation}>
                            {(formikProps) => (
                                <Form className={"flex flex-row flex-wrap gap-4"}>
                                    <div className={"mt-4 flex flex-col p-8 w-full lg:w-3/4 2xl:w-2/5 shadow-custom rounded-lg animate-fade-in"}>
                                        <div className={"flex flex-row w-full items-center"}>
                                            <button className={"rounded-full hover:bg-gray-50 mr-2"} type={"button"} onClick={() => history.goBack()}>
                                                <div className={"w-8 h-8 p-2"}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                        <path id="Icon_material-arrow_back" data-name="Icon material-arrow_back" d="M30,16.5H11.745L20.13,8.115,18,6,6,18,18,30l2.115-2.115L11.745,19.5H30Z" transform="translate(-6 -6)"/>
                                                    </svg>
                                                </div>
                                            </button>
                                            {(props.requestType !== "update") ?
                                                <p className={"font-bold text-primary text-lg"}>Crear plato</p> :
                                                <p className={"font-bold text-primary text-lg"}>Actualizar plato</p>}
                                        </div>
                                        <div className={"flex flex-col md:flex-row w-full mt-4"}>
                                            <ImagePickerComponent field={"image"} label={"Imagen*"} urlImage={plateState.image}/>
                                            <div className={"flex flex-col w-full md:pl-4"}>
                                                <div className={"flex flex-col"}>
                                                    <label htmlFor={"name"} className={labelStyles}>Nombre*</label>
                                                    <Field type={"text"} name={"name"} className={inputStyles} />
                                                    <ErrorMessage name={"name"}>{message =>
                                                        <p className={"text-red-500"}>{message}</p>
                                                    }</ErrorMessage>
                                                </div>
                                                <div className={"flex flex-col"}>
                                                    <label htmlFor={"type"} className={labelStyles}>Tipo*</label>
                                                    <Field as={"select"} name={"type"} className={inputStyles}>
                                                        {
                                                            options.map((options: {name: string, value: string}) => {
                                                                return (
                                                                    <option key={options.name} value={options.value} selected={(plateState.type === options.value)}>{options.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </Field>
                                                    <ErrorMessage name={"type"}>{message =>
                                                        <p className={"text-red-500"}>{message}</p>
                                                    }</ErrorMessage>
                                                </div>
                                            </div>
                                        </div>

                                        <TextAreaComponent field={"description"} label={"Descripción*"} labelStyles={labelStyles} inputStyle={inputStyles} description={formikProps.initialValues.description} setDescription={setDescription}/>

                                        <div className={"flex flex-col"}>
                                            <label htmlFor={"price"} className={labelStyles}>Precio</label>
                                            <Field type={"number"} name={"price"} className={inputStyles} />
                                            <ErrorMessage name={"price"}>{message =>
                                                <p className={"text-red-500"}>{message}</p>
                                            }</ErrorMessage>
                                        </div>

                                        <input type="submit" value={"Guardar"} className={"bg-primary text-white p-2 rounded-lg mt-2 cursor-pointer"}/>
                                    </div>
                                    <div className={"mt-4 flex flex-col p-8 w-full lg:w-3/4 2xl:w-1/2 shadow-custom rounded-lg animate-fade-in"}>
                                        <p className={"font-bold text-primary text-lg"}>Receta</p>

                                        <JoditEditor
                                            ref={editor}
                                            value={formikProps.values.recipe}
                                            config={config}
                                            onBlur={formikProps.handleBlur}
                                            onChange={(newContent: string) => {
                                                formikProps.setFieldValue("recipe", newContent)
                                            }}>
                                            <Interweave>
                                                
                                            </Interweave>
                                        </JoditEditor>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                </div>
            </div>

        </>
    );
};

export default PlateFormScreen;
