import React, {ChangeEvent, useState} from "react";
import {useFormikContext} from "formik";

interface IImageSelector {
    field: string,
    label: string,
}

const ImageSelector = (props: IImageSelector) => {
    const inputStyles = "border-1 border-primary rounded-lg p-1 mb-2";
    const labelStyles = "font-bold text-base text-primary mb-2";

    const [imageBase64, setImageBase64] = useState('');
    const { values } = useFormikContext<any>();

    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.currentTarget.files) {
            const file = e.currentTarget.files[0];
            toBase64(file)
                .then((value: string) => setImageBase64(value))
                .catch(error => console.error(error));

            values[props.field] = file;
        }
    };

    const toBase64 = (file: File) => {
        return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = (error) => reject(error);
        })
    };

    return (
        <div className={"flex flex-col w-full"}>
            <label className={labelStyles} htmlFor={props.field}>{props.label}</label>
            <input className={inputStyles} type="file" name={props.field} onChange={handleOnChange}/>
        </div>
    )
};

export default ImageSelector;
