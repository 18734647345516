import React, {useEffect, useState} from "react";

interface IPaginationComponent {
    currentPage: number,
    totalCountRecords: number,
    radio: number,
    onChange(page: number): void
}

interface linkModel {
    page: number,
    enable: boolean,
    text: string,
    active: boolean
}

const PaginationComponent = (props: IPaginationComponent) => {
    const [listedLinks, setListedLinks] = useState<linkModel[]>([]);

    useEffect(() => {
        const previousEnablePage = props.currentPage !== 1;
        const previousPage = props.currentPage - 1;
        const links: linkModel[] = [];
        const nextEnablePage = props.currentPage !== props.totalCountRecords && props.totalCountRecords > 0;
        const nextPage = props.currentPage + 1;

        links.push({text: 'Anterior', enable: previousEnablePage, page: previousPage, active: false});

        for(let i = 1; i <= props.totalCountRecords; i++) {
            if (i >= props.currentPage - props.radio && i <= props.currentPage + props.radio){
                links.push({
                    text: `${i}`,
                    active: props.currentPage === i,
                    enable: true,
                    page: i
                })
            }
        }

        links.push({text: 'Siguiente', enable: nextEnablePage, page: nextPage, active: false});

        setListedLinks(links);
    }, [props.currentPage, props.totalCountRecords, props.radio]);
    
    function pageSelector(link: linkModel) {
        if (link.page === props.currentPage){
            console.log("No, entra aqui");
            return;
        }

        if (!link.enable) {
            console.log("Entra aqui");
            return;
        }

        props.onChange(link.page);
    }

    function getClass(link: linkModel) {
        if (link.active && link.enable) {
            return "bg-primary hover:bg-primary text-white"
        } else if (!link.active && link.enable) {
            return "bg-white hover:bg-primary text-black hover:text-white"
        } else if (!link.enable && !link.active) {
            return "bg-gray-200 border-gray-200 text-gray-100 hover:bg-gray-200 hover:text-gray-100"
        }
    }

    return (
        <nav className={"flex flex-col justify-center"}>
            <ul className={"flex flex-row -space-x-px"}>
                {listedLinks.map(link =>
                    <li key={link.text}>
                        <a className={`py-2 px-3 mx-2 ml-0 leading-tight rounded-lg border border-primary ${getClass(link)}`} onClick={() => pageSelector(link)}>{link.text}</a>
                    </li>
                )}
            </ul>
        </nav>
    )
};

PaginationComponent.defaultProps = {
    radio: 1
};


export default PaginationComponent;
