import {useHistory, useParams} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import LoadingComponent from "../ui/LoadingComponent";
import {IUserForRegister} from "../../models/user";
import {ErrorMessage, Field, Form, Formik} from "formik";
import users from "../../api/users";
import {EyeIcon, EyeOffIcon} from "@heroicons/react/outline";
import {userValidation, userValidationForUpdate} from "../../validation/userValidation";
import {AuthContext} from "../../context/AuthContext";

interface IUserFormScreen {
    showNotification?: any,
    messageNotification?: any,
    requestType: string,
    showSidebar: boolean,
    setShowSidebar: any
}

interface IParams {
    id: string
}

const UserFormScreen = (props: IUserFormScreen) => {

    const labelStyles = "font-bold mb-2";
    const inputStyles = "border-1 border-primary rounded-lg p-1 mb-2";

    const initialState: IUserForRegister = {
        role: '',
        name: '',
        lastName: '',
        userName: '',
        password: ''
    };
    
    const options: any[] = [
        {
            name: 'Selecciona un rol',
            value: ''
        },
        {
            name: 'Alumno',
            value: 'alumno'
        },
        {
            name: 'Profesor',
            value: 'profesor'
        }
    ];

    const history = useHistory();
    const params = useParams<IParams>();
    const { user, userDispatch } = useContext(AuthContext);

    const [userState, setUserState] = useState(initialState);
    const [loading, setLoading] = useState(false);
    const [checked, setChecked] = useState(false);

    function handleAction(): void{
        if (checked)
            setChecked(false);
        else
            setChecked(true);
    }

    function onChangeShow() {
        if (props.showSidebar)
            props.setShowSidebar(false);
        else
            props.setShowSidebar(true)
    }

    function checkError(error: string) {
        if (error.includes('500')) {
            props.messageNotification('Error interno del servidor, comprueba internet y vuelve a intentarlo luego');
            props.showNotification(true);
        } else if (error.includes('404')) {
            props.messageNotification('El dato que quiere borrar no existe');
            props.showNotification(true);
        } else if (error.includes('400')) {
            props.messageNotification('La operación ha salido mal');
            props.showNotification(true);
        } else if (error.includes('Network Error')) {
            userDispatch({
                user: null,
            });
            history.replace('/login')
        } else {
            props.messageNotification('Error desconocido');
            props.showNotification(true);
        }
    }

    useEffect(() => {
        if (props.requestType === "update") {
            setLoading(true);
            users.get(parseInt(params.id), user?.user?.token as string)
                .then((response) => setUserState(response))
                .catch((error) => checkError(error.toString()))
                .finally(() => setLoading(false));
        }
    }, []);

    return (
        <>
            <div className={(loading) ? 'block w-full h-full flex flex-col justify-center items-center animate-fade-in ' : 'hidden'}>
                <div className={"w-40 h-40"}>
                    <LoadingComponent />
                </div>
            </div>
            <div className={(loading) ? "hidden" : "block md:py-4 md:px-20"}>
                <div className={"flex flex-row justify-between w-full"}>
                    <button className={"bg-white border-1 border-primary rounded-lg lg:hidden py-2 px-4"} onClick={onChangeShow}>
                        <svg xmlns="http://www.w3.org/2000/svg" className={"w-4 h-4"} viewBox="0 0 38 26.333">
                            <g id="Icon_feather-menu" data-name="Icon feather-menu" transform="translate(-3 -7.5)">
                                <path id="Trazado_115" data-name="Trazado 115" d="M4.5,18h35" transform="translate(0 2.667)" fill="none" stroke="#6B0DBC" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                                <path id="Trazado_116" data-name="Trazado 116" d="M4.5,9h35" transform="translate(0)" fill="none" stroke="#6B0DBC" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                                <path id="Trazado_117" data-name="Trazado 117" d="M4.5,27h35" transform="translate(0 5.333)" fill="none" stroke="#6B0DBC" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                            </g>
                        </svg>
                    </button>
                </div>
                <div className={"w-full h-full overflow-y-scroll"}>
                    <div className={"mt-4 flex flex-row animate-fade-in p-2"}>
                        <div className={"flex flex-col p-8 w-full lg:w-1/2 shadow-custom rounded-lg"}>
                            <div className={"flex flex-row w-full items-center "}>
                                <button className={"rounded-full hover:bg-gray-50 mr-2"} onClick={() => history.goBack()}>
                                    <div className={"w-8 h-8 p-2"}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                            <path id="Icon_material-arrow_back" data-name="Icon material-arrow_back" d="M30,16.5H11.745L20.13,8.115,18,6,6,18,18,30l2.115-2.115L11.745,19.5H30Z" transform="translate(-6 -6)"/>
                                        </svg>
                                    </div>
                                </button>
                                {(props.requestType !== "update") ?
                                    <p className={"font-bold text-primary text-lg"}>Crear usuario</p> :
                                    <p className={"font-bold text-primary text-lg"}>Actualizar usuario</p>}
                            </div>

                            <Formik
                                enableReinitialize={true}
                                initialValues={userState}
                                onSubmit={async values => {
                                    setLoading(true);

                                    if (props.requestType === "create") {
                                        await users.create(values, user?.user?.token as string, user?.user?.userResponse?.name as string + ' '
                                        + user?.user?.userResponse?.lastName as string)
                                            .then(() => {
                                                setLoading(false);
                                                history.goBack();
                                                props.messageNotification('Se ha insertado correctamente');
                                                props.showNotification(true);
                                            })
                                            .catch((error) => checkError(error.toString()))
                                            .finally(() => setLoading(false))
                                    } else if (props.requestType === "update"){
                                        await users.put(parseInt(params.id), values, user?.user?.token as string, user?.user?.userResponse?.name as string + ' '
                                        + user?.user?.userResponse?.lastName as string)
                                            .then(() => {
                                                setLoading(false);
                                                history.goBack();
                                                props.messageNotification('Se ha actualizado correctamente');
                                                props.showNotification(true);
                                            })
                                            .catch((error) => checkError(error.toString()))
                                            .finally(() => setLoading(false))
                                    }
                                }}
                                validationSchema={!(props.requestType === 'update') ? userValidation : userValidationForUpdate}
                            >{(formikProps) => (
                                <Form className={"mt-4"}>
                                    <div className={"flex flex-col"}>
                                        <label htmlFor={"name"} className={labelStyles}>Nombre*</label>
                                        <Field type={"text"} name={"name"} className={inputStyles} />
                                        <ErrorMessage name={"name"}>{message =>
                                            <p className={"text-red-500"}>{message}</p>
                                        }</ErrorMessage>
                                    </div>
                                    <div className={"flex flex-col"}>
                                        <label htmlFor={"lastName"} className={labelStyles}>Apellido*</label>
                                        <Field type={"text"} name={"lastName"} className={inputStyles} />
                                        <ErrorMessage name={"lastName"}>{message =>
                                            <p className={"text-red-500"}>{message}</p>
                                        }</ErrorMessage>
                                    </div>
                                    <div className={(props.requestType === "update") ? "hidden" : "flex flex-col"}>
                                        <label htmlFor={"userName"} className={labelStyles}>Nombre de usuario*</label>
                                        <Field type={"text"} name={"userName"} className={inputStyles} />
                                        <ErrorMessage name={"userName"}>{message =>
                                            <p className={"text-red-500"}>{message}</p>
                                        }</ErrorMessage>
                                    </div>
                                    <div className={(props.requestType === "update") ? "hidden" : "flex flex-col"}>
                                        <label htmlFor={"password"} className={labelStyles}>Contraseña*</label>
                                        <div className={"flex flex-row w-full"}>
                                            <Field name={"password"} className={inputStyles + " w-full"} type={checked ? "text" : "password"} />
                                            <EyeIcon className={checked ? "hidden" : "h-hull w-9 p-2 text-primary block cursor-pointer mb-2"} onClick={ handleAction }/>
                                            <EyeOffIcon className={checked ? "h-hull w-9 p-2 text-primary block cursor-pointer mb-2" : "hidden"} onClick={ handleAction }/>
                                        </div>
                                        <ErrorMessage name={"password"}>{message =>
                                            <p className={"text-red-500"}>{message}</p>
                                        }</ErrorMessage>
                                    </div>
                                    <div className="flex flex-col">
                                        <label htmlFor="role" className={labelStyles}>Rol</label>
                                        <Field as={"select"} name={"role"} className={inputStyles}>
                                            {
                                                options.map((option: {name: string, value: string}) => {
                                                    return (
                                                        <option key={option.name} value={option.value} selected={(userState.role === option.value)}>{option.name}</option>
                                                    )
                                                })
                                            }
                                        </Field>
                                        <ErrorMessage name={"role"}>{message =>
                                            <p className={"text-red-500"}>{message}</p>
                                        }</ErrorMessage>
                                    </div>

                                    <input type="submit" value={"Guardar"} className={"bg-primary text-white p-2 rounded-lg mt-2 cursor-pointer"}/>
                                </Form>
                            )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default UserFormScreen;
