import React from 'react';
import {Alignment, Fit, Layout, useRive, UseRiveOptions, UseRiveParameters} from "rive-react";

const LoadingComponent = () => {
    const params: UseRiveParameters = {
        src: '../../../aulaga-loading.riv',
        autoplay: true,
        artboard: 'main',
        animations: 'Animation 1',
        layout: new Layout({
            fit: Fit.Contain,
            alignment: Alignment.Center,
        })
    };

    const options: UseRiveOptions = {
        fitCanvasToArtboardHeight: false,
        useDevicePixelRatio: true
    };

    function styles() {
        return {
            "display": "flex",
            "flex-direction": "row",
            "justify-content": "center"
        }
    }

    const {RiveComponent} = useRive(params, options);

    return (
        <>
            <RiveComponent style={styles()} />
        </>
    );

};

export default LoadingComponent;
