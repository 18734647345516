import React, {useContext, useEffect, useReducer, useState} from 'react';
import SearchbarComponent from "../ui/SearchbarComponent";
import {IPlate} from "../../models/plate";
import {plateReducer} from "../../reducers/plateReducer";
import plate from "../../api/plate";
import LoadingComponent from "../ui/LoadingComponent";
import ModalComponent from "../ui/ModalComponent";
import { useHistory } from 'react-router-dom';
import {AuthContext} from "../../context/AuthContext";
import PaginationComponent from "../ui/PaginationComponent";
import NoImage from "../assets/img/noimage.webp";


interface IPlateListScreen {
    showNotification: any,
    messageNotification: any,
    showSidebar: boolean,
    setShowSidebar: any
}

const PlateListScreen = (props: IPlateListScreen) => {

    const titleStyle = "text-indigo-500 font-bold";
    const initialState: IPlate[] = [];

    const [state, dispatch] = useReducer(plateReducer, initialState);

    const history = useHistory();

    const { user, userDispatch } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [confirm, setConfirm] = useState({
        show: false,
        itemId: 0
    });
    const [totalPages, setTotalPages] = useState(0);
    const [countRecordsPerPages, setCountRecordsPerPages] = useState(8);
    const [currentPage, setCurrentPage] = useState(1);

    function onChangeShow() {
        if (props.showSidebar)
            props.setShowSidebar(false);
        else
            props.setShowSidebar(true)
    }

    function checkError(error: string) {
        if (error.includes('500')) {
            props.messageNotification('Error interno del servidor, comprueba internet y vuelve a intentarlo luego');
            props.showNotification(true);
        } else if (error.includes('404')) {
            props.messageNotification('El dato que quiere borrar no existe');
            props.showNotification(true);
        } else if (error.includes('400')) {
            props.messageNotification('La operación ha salido mal');
            props.showNotification(true);
        } else if (error.includes('Network Error')) {
            userDispatch({
                user: null,
            });
            history.replace('/login')
        } else {
            props.messageNotification('Error desconocido');
            props.showNotification(true);
        }
    }

    const onDelete = async (id: number) => {
        setLoading(true);
        plate.delete(id, user?.user?.token as string, user?.user?.userResponse?.name as string + ' '
        + user?.user?.userResponse?.lastName as string)
            .then(() => {
                props.messageNotification('Se ha borrado correctamente');
                props.showNotification(true);

                setLoading(true);
                plate.list(user?.user?.token as string, currentPage, countRecordsPerPages)
                    .then(response => {
                        const totalRecords = response.totalCountRecords;
                        setTotalPages(Math.ceil(totalRecords / countRecordsPerPages));

                        const action = {
                            type: 'charge',
                            payload: response.plates
                        };

                        dispatch(action);
                    }).catch((error) => checkError(error.toString())).finally(() => setLoading(false))
            })
            .catch((error) => checkError(error.toString())).finally(() => setLoading(false));
    };

    useEffect(() => {
        setLoading(true);
        plate.list(user?.user?.token as string, currentPage, countRecordsPerPages)
            .then(response => {
                const totalRecords = response.totalCountRecords;
                setTotalPages(Math.ceil(totalRecords / countRecordsPerPages));

                const action = {
                    type: 'charge',
                    payload: response.plates
                };

                dispatch(action);
            }).catch((error) => checkError(error.toString())).finally(() => setLoading(false));
    }, [currentPage, countRecordsPerPages]);

    function styles(url: string) {
        return {
            backgroundImage: `url(${url})`,
        }
    }

    return (
        <>
            <div className={(loading) ? 'block w-full h-full flex flex-col justify-center items-center animate-fade-in' : 'hidden'}>
                <div className={"w-40 h-40"}>
                    <LoadingComponent />
                </div>
            </div>
            <div className={ (loading) ? "hidden" : "block h-full w-full md:py-4 md:px-20 lg:w-auto"}>
                <div className={"flex flex-row justify-between w-full"}>
                    <button className={"bg-white border-1 border-primary rounded-lg lg:hidden py-2 px-4"} onClick={onChangeShow}>
                        <svg xmlns="http://www.w3.org/2000/svg" className={"w-4 h-4"} viewBox="0 0 38 26.333">
                            <g id="Icon_feather-menu" data-name="Icon feather-menu" transform="translate(-3 -7.5)">
                                <path id="Trazado_115" data-name="Trazado 115" d="M4.5,18h35" transform="translate(0 2.667)" fill="none" stroke="#6B0DBC" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                                <path id="Trazado_116" data-name="Trazado 116" d="M4.5,9h35" transform="translate(0)" fill="none" stroke="#6B0DBC" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                                <path id="Trazado_117" data-name="Trazado 117" d="M4.5,27h35" transform="translate(0 5.333)" fill="none" stroke="#6B0DBC" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                            </g>
                        </svg>
                    </button>
                    <SearchbarComponent type={"platos"} showSearchBar={false} />
                    <div className={"hidden md:flex flex-col justify-center items-center"}>
                        <PaginationComponent currentPage={currentPage} totalCountRecords={totalPages} onChange={newPage => setCurrentPage(newPage)}/>
                    </div>
                </div>
                <div className={"block md:hidden w-full h-16 flex flex-row justify-center"}>
                    <PaginationComponent currentPage={currentPage} totalCountRecords={totalPages} onChange={newPage => setCurrentPage(newPage)}/>
                </div>
                <div className="w-full h-full overflow-y-scroll px-2">
                    <div className={"py-8 flex flex-row justify-left flex-wrap animate-fade-in md:gap-2 lg:gap-4"}>
                        {state.map((value: any) => (
                            value.map((trueValue: IPlate) => (
                                <div key={trueValue.id} className={"flex flex-col w-full md:w-72 h-96 shadow-custom rounded-lg my-4"}>
                                    <div className={"h-44 w-full bg-cover p-2 bg-center bg-no-repeat rounded-lg"} style={(trueValue.image !== null) ? styles(trueValue.image) : styles(NoImage)}/>
                                    <div className={"flex flex-col h-auto p-4 w-full"}>
                                        <div className={"flex flex-row"}>
                                            <p className={titleStyle}>{trueValue.name}&nbsp;</p>
                                            <p className={"font-bold"}>- {trueValue.type}</p>
                                        </div>
                                        <div className={"flex flex-row mb-2"}>
                                            <p className={titleStyle}>Precio:&nbsp;</p>
                                            <p>{trueValue.price}€</p>
                                        </div>

                                        <p className={"break-all overflow-ellipsis overflow-hidden block text-sm h-20"}>{trueValue.description}</p>

                                        <div className={"flex flex-row mt-6"}>
                                            <button className={"text-indigo-500"} onClick={() => history.push(`/plates/update/${trueValue.id}`)}>Actualizar</button>
                                            <button className={"px-4 text-red-500"} onClick={() => {
                                                setConfirm({
                                                    show: true,
                                                    itemId: trueValue.id
                                                });
                                            }}>Borrar</button>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ))}
                    </div>
                </div>
            </div>

            <ModalComponent state={confirm} setState={setConfirm} onConfirm={onDelete}/>
        </>
    );
};

export default PlateListScreen;
