import React, {useState} from "react";
import {SearchIcon} from "@heroicons/react/solid";
import {Link} from "react-router-dom";

interface ISearchbarProps {
    type?: string,
    showSearchBar?: boolean,
}

const SearchbarComponent = (props: React.PropsWithoutRef<ISearchbarProps>) => {

    function refreshPage() {
        window.location.reload()
    }

    return (
        <div className={((props.showSearchBar) ? "justify-between" : "justify-end") + " flex flex-row h-10"}>
            <div className={(props.showSearchBar) ? "flex flex-row w-1/4 h-full px-2 py-4 items-center justify-center border-primary border-1 rounded-lg block" : "hidden"}>
                <SearchIcon className={"h-5 w-5 mr-2 text-primary"} />
                <input className={"w-full"} type="text" placeholder={ "Buscar " + props.type }/>
            </div>

            <div className={"flex flex-row"}>
                <button className={"bg-primary rounded-lg mr-4 p-2 transition duration-300 ease-in-out hover:bg-primary-hover"} onClick={refreshPage}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="23.985" height="24" viewBox="0 0 23.985 24">
                        <path fill={"#fff"} id="Icon_material-refresh" data-name="Icon material-refresh" d="M26.475,9.525A12,12,0,1,0,29.6,21h-3.12A9,9,0,1,1,18,9a8.872,8.872,0,0,1,6.33,2.67L19.5,16.5H30V6Z" transform="translate(-6.015 -6)"/>
                    </svg>
                </button>
                <button className={"bg-primary rounded-lg text-white transition duration-300 ease-in-out hover:bg-primary-hover"}>
                    <Link to={"/day/new"} className={"w-full h-full p-2 " + ((props.type === 'días') ? 'block' : 'hidden')}>Agregar { props.type }</Link>
                    <Link to={"/week-menus/new"} className={"w-full h-full p-2 " + ((props.type === 'menú semanal') ? 'block' : 'hidden')}>Agregar { props.type }</Link>
                    <Link to={"/plates/new"} className={"w-full h-full p-2 " + ((props.type === 'platos') ? 'block' : 'hidden')}>Agregar { props.type }</Link>
                    <Link to={"/users/new"} className={"w-full h-full p-2 " + ((props.type === 'usuarios') ? 'block' : 'hidden')}>Agregar { props.type }</Link>
                </button>
            </div>

        </div>
    )
};

export default SearchbarComponent;
